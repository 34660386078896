import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const vacationMilestoneApi = createApi({
  reducerPath: "vacationMilestones",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/vacationMilestone`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchVacationMilestoneById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "VacationMilestone" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateVacationMilestoneById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "VacationMilestone" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
    };
  },
});

export const {
  useFetchVacationMilestoneByIdQuery,
  useUpdateVacationMilestoneByIdMutation,
} = vacationMilestoneApi;
export { vacationMilestoneApi };
