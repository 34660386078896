import React from "react";
import Message from "./Message";
import { useSelector, useDispatch } from "react-redux";
import { removeMesaage } from "../store/slices/messageSlice";

const MessageArray = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    // {
    // message : 'This is a message',
    // variant : 'success'
    // }
    return state.messages;
  });

  const handleDismiss = (i) => {
    dispatch(removeMesaage(i));
  };

  const content = messages.data.map((message, i) => {
    //Only return the first 3 messages
    if (i > 1) return null;

    // const text = message.message;
    //split the message on \n
    // const lines = text.split("\n");
    //if there is only one line, return the message
    // if (lines.length === 1) {
    return (
      <Message
        key={message.id}
        variant={message.variant}
        i={i}
        clearMessage={() => {
          handleDismiss(message.id);
        }}
      >
        {message.message}
      </Message>
    );
    // } else {
    //   return (
    //     <Message
    //       key={message.id}
    //       variant={message.variant}
    //       i={i}
    //       clearMessage={() => {
    //         handleDismiss(message.id);
    //       }}
    //     >
    //       <ul>
    //         {lines.map((line, index) => {
    //           return <li key={index}>{line}</li>;
    //         })}
    //       </ul>
    //     </Message>
    //   );
    // }
  });

  return content;
};

export default MessageArray;
