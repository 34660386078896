import React from "react";
import { Form } from "react-bootstrap";
import { cloneDeep, isEqual } from "lodash";
import SortableTable from "../table/SortableTable";
import useSort from "../../hooks/useSort";
import { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SecurityLevelFieldAccess = ({
  state,
  field,
  handleChange,
  isDisabled,
  isLoading,
  id,
}) => {
  const handleCustomChange = (e, i, option) => {
    const newState = cloneDeep(state[field]);
    newState[i].actions[option] = e.target.checked;

    handleChange({ target: { name: field, value: newState } });
  };

  const { sortOrder, sortBy, setSortColumn } = useSort(
    "field", // startingSort,
    "asc" // startingSortOrder
  );

  useEffect(() => {
    if (!state[field]) return;

    //The read / updates can be true, false, or undefined.  We need to convert undefined to a number so we can sort it.
    const getValue = (data, sortBy) => {
      let aField = data[sortBy];
      if (aField === undefined) {
        aField = data.actions[sortBy];
        if (aField === true) {
          aField = 0;
        } else if (aField === false) {
          aField = 1;
        } else {
          aField = 2;
        }
      }
      return aField;
    };

    //sort state[field] by sortBy and sortOrder
    const sortedData = cloneDeep(state[field]).sort((a, b) => {
      const aField = a[sortBy] || getValue(a, sortBy);
      const bField = b[sortBy] || getValue(b, sortBy);

      if (aField < bField) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (aField > bField) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

    for (let i = 0; i < sortedData.length; i++) {
      const element = sortedData[i];
      element.i = i;
    }

    if (!isEqual(sortedData, state[field])) {
      console.log(sortedData);
      handleChange({ target: { name: field, value: sortedData } });
    }
    // eslint-disable-next-line
  }, [sortOrder, sortBy]);

  const config = [
    {
      label: "Field",
      render: (data) => {
        return data.field;
      },
      sortValue: "field",
      filterValue: "field",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Reference To",
      render: (data) => {
        if (!data.field) return "";
        else if (data.cType === "Reference" && data.cReferenceTable) {
          return (
            <>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>
                    This field is a reference to the {data.cReferenceTable}{" "}
                    table. For the user to view this field they need read access
                    to the {data.cReferenceTable} table.
                  </Tooltip>
                }
              >
                <div className='flex items-center justify-center'>
                  <span className=''>{data.cReferenceTable}</span>
                  <img
                    src='/images/Help.svg'
                    alt='filter'
                    className='w-[24px] ml-2 hidden md:block'
                  />
                </div>
              </OverlayTrigger>
            </>
          );
        } else return "";
      },
    },

    {
      label: "Read",
      render: (data) => {
        return getCheckBox(data, "read");
      },

      sortValue: "read",
      filterValue: "read",
      filterType: "Boolean",
    },
    {
      label: "Update",
      render: (data) => {
        return getCheckBox(data, "update");
      },
      sortValue: "update",
      filterValue: "update",
      filterType: "Boolean",
    },
  ];

  const getCheckBox = (data, option) => {
    if (data.actions.hasOwnProperty(option) === false) {
      return <></>;
    }

    return (
      <Form.Check
        disabled={isDisabled}
        type='checkbox'
        // label={item.label}
        name={option}
        checked={data.actions[option] || false}
        onChange={(e) => {
          handleCustomChange(e, data.i, option);
        }}
      ></Form.Check>
    );
  };

  //What is the key for the data?
  const keyFn = (field) => {
    return field.i;
  };

  if (id === "-1") return <></>;

  return (
    <div className={`flex items-center justify-center grow my-2 w-full`}>
      <div
        className={`w-[80%] z-40 my-5 relative shadow-sj rounded-sj bg-[#EFEFEF]`}
      >
        <div className='flex justify-center w-full px-1 md:px-3 py-4 shadow-sj-btm rounded-t-sj relative'>
          <div className='flex-col justify-center'>
            <div className='font-bold text-2xl text-center my-4'>
              Field Access
            </div>
            <div className='text-md lg:text-lg mb-4 px-8'>
              Users with the{" "}
              <span className='text-primary-light'>{state.role}</span> role will
              be able to read / update the following fields on a{" "}
              <span className='text-primary-light'>{state.table}</span> record.
            </div>
          </div>
        </div>

        <div className='flex justify-center w-full mb-4'>
          {!isLoading && state[field] && (
            <SortableTable
              config={config}
              keyFn={keyFn}
              data={state[field]}
              hideLink={true}
              isFetching={isLoading}
              getCheckBox={getCheckBox}
              setSortColumn={setSortColumn}
              sortBy={sortBy}
              sortOrder={sortOrder}
              canSort={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SecurityLevelFieldAccess;
