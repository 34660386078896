import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchProjectByIdQuery,
  useUpdateProjectByIdMutation,
  useDeleteProjectByIdMutation,
  useCreateProjectMutation,
  useFetchUsersQuery,
  useFetchClientsQuery,
  useFetchTasksQuery,
  useFetchRateCardsQuery,
} from "../store";
import { projectApi } from "../store/apis/projectApi";
import { userApi } from "../store/apis/userApi";
import { clientApi } from "../store/apis/clientApi";
import { formViewApi } from "../store/apis/formViewApi";
import { taskApi } from "../store/apis/taskApi";
import { rateCardApi } from "../store/apis/rateCardApi";

import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

const ProjectForm = () => {
  const storeApi = {
    fetch: useFetchProjectByIdQuery,
    update: useUpdateProjectByIdMutation,
    delete: useDeleteProjectByIdMutation,
    create: useCreateProjectMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchFormViewByTableQuery("project");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel, secLevelTask] = useSecLevel(["project", "task"]);

  const userRelatedListConfig = {
    config: [
      {
        label: "Name",
        render: (user) => user.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Email",
        render: (user) => user.email,
        sortValue: "email",
        // render: (user) => <div className={`p-3 m-2 ${user.color}`}></div>,
        filterValue: "email",
        filterType: "String",
      },
      {
        label: "Active", //header label
        render: (user) => user.active.toString(), //How to render the cell
        sortValue: "active",
        filterValue: "active",
        filterType: "Boolean",
      },
    ],
    keyFn: (user) => {
      return user._id;
    },
    recordNames: {
      title: "Select a User",
      formLink: "user",
      listLink: "users",
    },
    listApi: userApi,
    useFetchQuery: useFetchUsersQuery,
  };

  const config = [
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Status",
      field: "status",
      defaultValue: "",
      inputType: "select",
      render: (data) => data.status,
      selectOptions: ["Draft", "Open", "On Hold", "Closed"],
      validation: Yup.string()
        .required("Status is required")
        .oneOf(
          ["Draft", "Open", "On Hold", "Closed"],
          "Status must be one of Draft, Open, On Hold, or Closed"
        ),
    },
    {
      label: "Client",
      field: "client",
      defaultValue: "",
      handleIsDisabled: (id, data) => id !== "-1",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.client?.name || "",
      id: (data) => data?.client?._id || "",
      useFetchQuery: useFetchClientsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid client",
          excludeEmptyString: true,
        })
        .required("Client is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (client) => client.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Number",
            render: (client) => client.number,
            sortValue: "number",
            // render: (client) => <div className={`p-3 m-2 ${client.color}`}></div>,
            filterValue: "number",
            filterType: "String",
          },
          {
            label: "Client ID", //header label
            render: (client) => client.clientId, //How to render the cell
            sortValue: "clientId",
            filterValue: "clientId",
            filterType: "String",
          },
        ],
        keyFn: (client) => {
          return client._id;
        },
        recordNames: {
          title: "Select a Client",
          formLink: "client",
          listLink: "clients",
        },
        listApi: clientApi,
        useFetchQuery: useFetchClientsQuery,
      },
    },
    {
      label: "Project Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Project name must be at least 2 characters")
        .required("Project name is required"),
    },
    {
      label: "Project Manager",
      field: "projectManager",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.projectManager?.name || "",
      id: (data) => data?.projectManager?._id || "",
      useFetchQuery: useFetchUsersQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid user",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: userRelatedListConfig,
    },
    {
      label: "Finance Manager",
      field: "financeManager",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.financeManager?.name || "",
      id: (data) => data?.financeManager?._id || "",
      useFetchQuery: useFetchUsersQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid user",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: userRelatedListConfig,
    },
    {
      label: "Rate Card",
      field: "rateCard",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.rateCard?.name || "",
      id: (data) => data?.rateCard?._id || "",
      useFetchQuery: useFetchRateCardsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string().matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid rate card",
        excludeEmptyString: true,
      }),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (rateCard) => rateCard.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Number",
            render: (rateCard) => rateCard.number,
            sortValue: "number",
            // render: (rateCard) => <div className={`p-3 m-2 ${rateCard.color}`}></div>,
            filterValue: "number",
            filterType: "String",
          },
          {
            label: "Active", //header label
            render: (rateCard) => rateCard.active.toString(), //How to render the cell
            sortValue: "active",
            filterValue: "active",
            filterType: "Boolean",
          },
        ],
        keyFn: (rateCard) => {
          return rateCard._id;
        },
        recordNames: {
          title: "Select a Rate Card",
          formLink: "rateCard",
          listLink: "rateCards",
        },
        listApi: rateCardApi,
        useFetchQuery: useFetchRateCardsQuery,
      },
    },
    {
      label: "Start Date",
      field: "startDate",
      defaultValue: "",
      inputType: "date",
      render: (data) => {
        if (data.startDate) {
          return data?.startDate.toString().substring(0, 10);
        } else {
          return "";
        }
      },
      validation: Yup.date()
        .min(new Date(1900, 0, 1), "Date cannot be before the year 1900.")
        .max(new Date(2100, 0, 1), "Date cannot be after the year 2100.")
        .required("Start date is required")
        .max(Yup.ref("endDate"), "Start date must be before end date")
        .nullable()
        .typeError("Start date is required"),
    },
    {
      label: "End Date",
      field: "endDate",
      defaultValue: "",
      inputType: "date",
      render: (data) => {
        if (data.endDate) {
          return data?.endDate.toString().substring(0, 10);
        } else {
          return "";
        }
      },
      validation: Yup.date()
        .min(new Date(1900, 0, 1), "Date cannot be before the year 1900.")
        .max(new Date(2100, 0, 1), "Date cannot be after the year 2100.")
        .required("End date is required")
        .min(Yup.ref("startDate"), "End date must be after start date")
        .nullable()
        .typeError("End date is required"),
    },
    {
      label: "Description",
      field: "description",
      defaultValue: "",
      inputType: "textarea",
      col: "12",
      render: (data) => data.description,
    },
    {
      label: "Active",
      field: "active",
      render: (data) => data.active,
      defaultValue: true,
      inputType: "checkbox",
    },
    {
      label: "Billable",
      field: "billable",
      render: (data) => data.billable,
      defaultValue: true,
      inputType: "checkbox",
    },
    {
      label: "Allow Time Entry",
      field: "allowTimeEntry",
      render: (data) => data.allowTimeEntry,
      defaultValue: true,
      inputType: "checkbox",
    },
  ];

  const { id } = useParams();

  //This is an array of related lists. Each element will be a tab on the realted list

  let relatedListConfig = [
    //User Roles Related List
    {
      secLevel: secLevelTask,

      //Function used as the key on the list
      keyFn: (task) => {
        return task._id;
      },

      recordNames: {
        title: "Tasks",
        formLink: "task",
        listLink: "task",
      },
      listApi: taskApi,

      useFetchQuery: useFetchTasksQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `project._id=${id}^`,
      config: [
        {
          label: "i",
          render: (task) => `/task/${task._id}`,
        },
        {
          label: "Number",
          render: (task) => task.number,
          sortValue: "number",
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Task Name",
          render: (task) => task.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Status",
          render: (task) => task.status,
          sortValue: "status",
          filterValue: "status",
          filterType: "String",
        },
      ],
    },
  ];

  //Filter out any related lists the user doesn't have access to
  relatedListConfig = relatedListConfig.filter((rl) => {
    return rl.secLevel && rl.secLevel.read;
  });

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={projectApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={relatedListConfig}
        secLevel={secLevel}
        formTitle='Project'
        table='project'
        redirect='project'
      />
    ))
  );
};

export default requireAuth(ProjectForm);
