import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  useRegisterUserMutation,
  useVerifyEmailMutation,
  useVerifyCodeMutation,
} from "../store/index";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../store/index";
import Loader from "../components/Loader";
import { addMessage } from "../store/slices/messageSlice";
import * as Yup from "yup";
import PasswordInput from "../components/PasswordInput";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [registerUser, results] = useRegisterUserMutation();
  const [verifyEmail, verifyEmailResults] = useVerifyEmailMutation();
  const [verifyCode, verifyCodeResults] = useVerifyCodeMutation();

  const [pageStatus, setPageStatus] = useState("verifyEmail"); //verifyEmail, enterCode, signUp

  useEffect(() => {
    if (verifyEmailResults.data && verifyEmailResults.data.success) {
      // dispatch(
      //   addMessage({
      //     variant: "success",
      //     message: verifyEmailResults.data.message,
      //   })
      // );
      setPageStatus("enterCode");
    } else if (verifyEmailResults.isError) {
      dispatch(
        addMessage({
          variant: "danger",
          message: verifyEmailResults.error.data.message,
        })
      );
    }
  }, [verifyEmailResults, dispatch]);

  useEffect(() => {
    if (results.data) {
      dispatch(setCredentials(results));
      navigate("/");
      dispatch(
        addMessage({ variant: "success", message: "Welcome to ScopeJolt!" })
      );
    } else if (results.isError) {
      dispatch(
        addMessage({ variant: "danger", message: results.error.data.message })
      );
      if (results.error.data.message.indexOf("new code") !== -1) {
        setPageStatus("verifyEmail");
      }
    }
  }, [results, dispatch, navigate]);

  useEffect(() => {
    if (verifyCodeResults.data && verifyCodeResults.data.success) {
      setPageStatus("signUp");
    } else if (verifyCodeResults.isError) {
      dispatch(
        addMessage({
          variant: "danger",
          message: verifyCodeResults.error.data.message,
        })
      );
    }
  }, [verifyCodeResults, dispatch]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [company, setCompany] = useState("");
  const [errors, setErrors] = useState({});

  const yupVerifyEmail = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .required("Email is Required"),
  });

  const verifyEmailHandler = async (e) => {
    e.preventDefault();

    try {
      await yupVerifyEmail.validate(
        {
          email,
        },
        { abortEarly: false }
      );
      setErrors({});

      verifyEmail({
        email,
      });
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  const yupVerifyCode = Yup.object().shape({
    code: Yup.string()
      .matches(/^\d{6}$/, "Invalid code")
      .required("Code is Required"),
  });

  const verifyCodeHandler = async (e) => {
    e.preventDefault();
    //remove white spaces

    const myCode = code.trim(); // code.join("");
    try {
      await yupVerifyCode.validate(
        {
          code: myCode,
        },
        { abortEarly: false }
      );
    } catch (err) {
      if (err.inner) {
        dispatch(
          addMessage({
            variant: "danger",
            message: "Invalid code",
          })
        );
        return;
      }
    }

    verifyCode({
      email: email,
      code: myCode,
    });
  };

  const yupShape = {
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required(),
    firstname: Yup.string()
      .min(2, "First name must be at least 2 characters")
      .required("First name is required"),
    lastname: Yup.string()
      .min(2, "First name must be at least 2 characters")
      .required("Last name is required"),
    company: Yup.string()
      .min(2, "Company name must be at least 2 characters")
      .required("Company name is required"),
    zipCode: Yup.string()
      .matches(/^\d{5}$/, "Billing zip code must be 5 digits long")
      .required("Billin zip code is required"),
  };

  const validationSchema = Yup.object().shape(yupShape);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          password,
          confirmPassword,
          firstname,
          lastname,
          company,
          zipCode,
        },
        { abortEarly: false }
      );
      setErrors({});

      registerUser({
        email,
        password,
        confirmPassword,
        firstname,
        lastname,
        company,
        zipCode,
      });
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  const contentVerifyEmail = (
    <>
      <div className='flex flex-col w-full justify-center text-center'>
        <h1 className='text-2xl py-1'>Create your free account</h1>
        <p className='py-1'>or</p>
        <NavLink to='/login'>
          <div className='hover-underline cursor-pointer text-blue-500'>
            Log in
          </div>
        </NavLink>
      </div>
      <Form
        onSubmit={verifyEmailHandler}
        className='max-w-md flex justify-center flex-col mx-auto py-12'
      >
        <Form.Group className='mb-3' controlId='email'>
          <Form.Label className='text-base'>
            Email
            {errors.email?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <Form.Control
            type='text'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder='Enter email'
            className='rounded-lg'
          />
          {errors.email && (
            <span className='form-field-error'>{errors.email}</span>
          )}
        </Form.Group>
        <Button
          variant='primary'
          type='submit'
          disabled={verifyEmailResults.isLoading}
          className='w-full my-3 primary-light-btn'
        >
          {verifyEmailResults.isLoading ? "Loading ... " : "Verify Email"}
        </Button>
      </Form>
    </>
  );

  const [code, setCode] = useState([""]);

  const handleCodeChange = (e, i) => {
    const value = e.target.value.replace(/[^0-9\s\b]/g, "");
    setCode(value.trim());

    // const nextIndex = i + 1;
    // if (nextIndex < code.length && value !== "") {
    //   document.querySelector(`input[name=input-${nextIndex}]`).focus();
    //   document.querySelector(`input[name=input-${nextIndex}]`).select();
    // }

    // const newCode = [...code];
    // newCode[i] = value;
    // setCode(newCode);
  };

  const contentEnterCode = (
    <>
      <Form
        onSubmit={verifyCodeHandler}
        className='max-w-md flex justify-center flex-col mx-auto py-4 rounded-lg'
      >
        <Form.Group className='mb-8' controlId='code'>
          <div className='flex flex-col justify-center mb-3 text-center'>
            <p className='text-2xl'>We've emailed you a code</p>
            <p className='text-base'>
              To continue, enter the code from the email sent to :
            </p>
            <p className='my-3 py-3 font-bold'>{email}</p>
          </div>
          <div className='flex grow justify-center'>
            {/* <div className='flex justify-center grow mb-5'>
            {code.map((c, i) => (
              <Form.Control
                className='verification-input rounded-lg'
                type='text'
                name={`input-${i}`}
                key={i}
                value={c}
                onChange={(e) => handleCodeChange(e, i)}
              />
            ))}
          </div> */}
            <Form.Control
              className='verification-input rounded-lg'
              type='text'
              name='code'
              value={code}
              onChange={(e) => handleCodeChange(e)}
            />
          </div>
        </Form.Group>
        <div className='flex justify-center grow'>
          <Button
            variant='primary'
            type='submit'
            className='primary-light-btn w-half'
          >
            Verify Code
          </Button>
        </div>
      </Form>
    </>
  );

  const contentAfterEmailVerified = (
    <>
      <div className='flex justify-center grow text-2xl'>
        <p>You're almost there!</p>
      </div>
      <Form
        onSubmit={submitHandler}
        className='max-w-md flex justify-center flex-col mx-auto py-10'
      >
        <Form.Group className='mb-3' controlId='firstname'>
          <Form.Label>
            First Name
            {errors.firstname?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <Form.Control
            type='text'
            value={firstname}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            placeholder='First Name'
            className='rounded-lg'
          />
          {errors.firstname && (
            <span className='form-field-error'>{errors.firstname}</span>
          )}
        </Form.Group>
        <Form.Group className='mb-3' controlId='lastname'>
          <Form.Label>
            Last Name
            {errors.lastname?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <Form.Control
            type='text'
            value={lastname}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            placeholder='Last Name'
            className='rounded-lg'
          />
          {errors.lastname && (
            <span className='form-field-error'>{errors.lastname}</span>
          )}
        </Form.Group>
        <Form.Group className='mb-3' controlId='company'>
          <Form.Label>
            Company Name
            {errors.company?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <Form.Control
            type='text'
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            placeholder="Your Company's Name"
            className='rounded-lg'
          />

          {errors.company && (
            <span className='form-field-error'>{errors.company}</span>
          )}
        </Form.Group>
        <Form.Group className='mb-3' controlId='zipCode'>
          <Form.Label>
            Billing Zip Code
            {errors.zipCode?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <Form.Control
            type='text'
            value={zipCode}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
            placeholder='Billing Zip Code'
            className='rounded-lg'
          />

          {errors.zipCode && (
            <span className='form-field-error'>{errors.zipCode}</span>
          )}
        </Form.Group>

        <Form.Group className='mb-3' controlId='password'>
          <Form.Label>
            Password
            {errors.password?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <PasswordInput
            password={password}
            onChange={setPassword}
            className='rounded-lg'
          />
          {errors.password && (
            <span className='form-field-error'>{errors.password}</span>
          )}
        </Form.Group>
        <Form.Group className='mb-3' controlId='confirmPassword'>
          <Form.Label>
            Confirm Password
            {errors.confirmPassword?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <PasswordInput
            password={confirmPassword}
            onChange={setConfirmPassword}
            className='rounded-lg'
          />

          {errors.confirmPassword && (
            <span className='form-field-error'>{errors.confirmPassword}</span>
          )}
        </Form.Group>
        <Button
          variant='primary'
          type='submit'
          disabled={results.isLoading}
          className='w-full my-2 primary-light-btn'
        >
          {results.isLoading ? "Loading ... " : "Sign Up"}
        </Button>
      </Form>
    </>
  );

  return (
    <div className='my-20 px-16'>
      <div className='flex w-full justify-center'>
        <img className='logo mb-3' src='../images/Logo Dark.svg' alt='Logo' />
      </div>
      {results.isLoading && <Loader />}
      {pageStatus === "verifyEmail" && contentVerifyEmail}
      {pageStatus === "enterCode" && contentEnterCode}
      {pageStatus === "signUp" && contentAfterEmailVerified}
    </div>
  );
};

export default Register;
