import requireAuth from "../requireAuth";
import ContentHeader from "../components/ContentHeader";
import moment from "moment";

import { timeSheetApi } from "../store/apis/timeSheetApi";
import { holidayApi } from "../store/apis/holidayApi";
import { reportingApi } from "../store/apis/reportingApi";
import {
  useFetchHomePageDataQuery,
  useFetchTimeSheetsQuery,
  useFetchHolidaysQuery,
} from "../store";
import { formatDate } from "../hooks/listHooks";
import { useSelector } from "react-redux";
import List from "../components/List";
import Card from "../components/Card";
import { Row } from "react-bootstrap";

const Homepage = () => {
  const { userId, usersName } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      userId: state.auth.id, //use this to limit access to the form
      usersName: state.auth.name,
    };
  });

  //Current Time Sheets
  const timeSheetConfig = [
    {
      label: "i",
      render: (timeSheet) => `/timeSheet/${timeSheet._id}`,
    },
    {
      label: "Number",
      render: (timeSheet) => timeSheet.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String",
    },
    {
      label: "Week Starts", //header label
      render: (timeSheet) => {
        return formatDate(timeSheet.weekStarts) || "";
        // return new Date(timeSheet.startDate).toString();
      }, //How to render the cell
      sortValue: "weekStarts",
      filterValue: "weekStarts",
      filterType: "Date",
    },
    {
      label: "User",
      render: (timeSheet) => timeSheet?.user?.name || "",
      sortValue: "user",
      filterValue: "user",
      filterType: "String",
    },
    {
      label: "Total Hours",
      render: (timeSheet) => timeSheet.totalHours,
      sortValue: "totalHours",
      filterValue: "totalHours",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
  ];

  //What is the key for the data?
  const keyFn = (timeSheet) => {
    return timeSheet._id;
  };

  const recordNames = {
    title: "Time Sheets",
    formLink: "timeSheet",
    listLink: "timeSheet", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  //Upcoming Holidays
  const holidayConfig = [
    {
      label: "Name",
      render: (holiday) => holiday.name || "",
      sortValue: "name",
      filterValue: "name",
      filterType: "String",
    },
    {
      label: "Date", //header label
      render: (holiday) => {
        return formatDate(holiday.date) || "";
      }, //How to render the cell
      sortValue: "date",
      filterValue: "date",
      filterType: "Date",
    },
  ];

  //What is the key for the data?
  const holidayKeyFn = (holiday) => {
    return holiday._id;
  };

  const holidayRecordNames = {
    title: "Holidays",
    formLink: "holiday",
    listLink: "holiday", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };
  const headerClasses =
    "h-[50px] text-xl lg:h-[75px] text-center shadow-sj bg-neutral-150 lg:text-2xl font-bold flex justify-center flex-col w-full z-20 rounded-t-sj";
  const timeSheetsContent = (
    <>
      <div className={`${headerClasses} relative`}>Current Time Sheets</div>
      <List
        config={timeSheetConfig}
        keyFn={keyFn}
        listApi={timeSheetApi}
        defaultFilter={`user._id=${userId}^`}
        useFetchQuery={useFetchTimeSheetsQuery}
        recordNames={recordNames}
        showHeader={false}
        showPagination={false}
        maxPageSize={4}
        defaultSortBy={"weekStarts"}
        canSort={false}
      />
    </>
  );

  const holidaysContent = (
    <>
      <div className={`${headerClasses} relative`}>Upcoming Holidays</div>
      <List
        config={holidayConfig}
        keyFn={holidayKeyFn}
        listApi={holidayApi}
        defaultFilter={`date>${moment().format("YYYY-MM-DD")}^date<${moment()
          .add(6, "months")
          .format("YYYY-MM-DD")}`}
        useFetchQuery={useFetchHolidaysQuery}
        recordNames={holidayRecordNames}
        showHeader={false}
        showPagination={false}
        maxPageSize={8}
        defaultSortBy={"date"}
        defaultSortOrder={"asc"}
        canSort={false}
        hideLink={true}
      />
    </>
  );

  const vacationConfig = [
    {
      label: "Balance",
      render: (row) => row.balanceName || "",
      sortValue: "balanceName",
      filterValue: "balanceName",
      filterType: "String",
    },
    {
      label: "Year to Date (YTD)", //header label
      render: (row) => row.ytd || "0",
      sortValue: "ytd",
      filterValue: "ytd",
      filterType: "Number",
    },
    {
      label: "Year End (YE)", //header label
      render: (row) => row.ye || "0",
      sortValue: "ye",
      filterValue: "ye",
      filterType: "Number",
    },
  ];

  //What is the key for the data?
  const vacationKeyFn = (vacation) => {
    return vacation.balanceName;
  };

  const vacationRecordNames = {
    title: "vacations",
    formLink: "userVacation",
    listLink: "userVacation", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const vacationBalancesContent = (
    <>
      <div className={`${headerClasses} relative`}>Vacation Balances</div>
      <List
        config={vacationConfig}
        keyFn={vacationKeyFn}
        listApi={reportingApi}
        useFetchQuery={useFetchHomePageDataQuery}
        recordNames={vacationRecordNames}
        showHeader={false}
        showPagination={false}
        canSort={false}
        hideLink={true}
      />
    </>
  );

  return (
    <>
      <div className='min-h-screen min-w-full bg-sj-gradient'>
        <ContentHeader
          title='Home'
          subtitle={usersName}
          align='center'
          className='mb-3'
        />
        <Row className='flex justify-center w-full grow flex-col lg:flex-row no-gutters'>
          <Card columns={7}>{timeSheetsContent}</Card>
          <Card columns={3}>{holidaysContent}</Card>
          <Card columns={7}>{vacationBalancesContent}</Card>
          <Card columns={3} isPlaceholder={true}></Card>
        </Row>
      </div>
    </>
  );
};

export default requireAuth(Homepage);
