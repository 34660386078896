import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { authApi } from "./apis/authApi";
import { userApi } from "./apis/userApi";
import { usersRoleApi } from "./apis/usersRoleApi";
import { roleApi } from "./apis/roleApi";
import { departmentApi } from "./apis/departmentApi";
import { timezoneApi } from "./apis/timezoneApi";
import { listViewApi } from "./apis/listViewApi";
import { formViewApi } from "./apis/formViewApi";
import { fieldSecurityLevelApi } from "./apis/fieldSecurityLevelApi";
import { fieldSecurityLevelsRoleApi } from "./apis/fieldSecurityLevelsRoleApi";
import { tableSecurityLevelApi } from "./apis/tableSecurityLevelApi";
import { tableSecurityLevelsRoleApi } from "./apis/tableSecurityLevelsRoleApi";
import { clientApi } from "./apis/clientApi";
import { projectApi } from "./apis/projectApi";
import { taskApi } from "./apis/taskApi";
import { titleApi } from "./apis/titleApi";
import { rateCardApi } from "./apis/rateCardApi";
import { rateApi } from "./apis/rateApi";
import { numberMaintenanceApi } from "./apis/numberMaintenanceApi";
import { timeSheetApi } from "./apis/timeSheetApi";
import { holidayApi } from "./apis/holidayApi";
import { pivotFilterApi } from "./apis/pivotFilterApi";
import { impersonateApi } from "./apis/impersonateApi";
import { reportingApi } from "./apis/reportingApi";
import { ticketApi } from "./apis/ticketApi";
import { securityLevelApi } from "./apis/securityLevelApi";
import { tableApi } from "./apis/tableApi";
import { vacationMilestoneApi } from "./apis/vacationMilestoneApi";
import { leaveTypeApi } from "./apis/leaveTypeApi";
import { userVacationApi } from "./apis/userVacationApi";

import { reset } from "./actions";

import { authReducer } from "./slices/authSlice";
import { setCredentials } from "./slices/authSlice";
import { messageReducer } from "./slices/messageSlice";

import { utilReducer } from "./slices/utilSlice";
import { toggleLeftNavCollapse } from "./slices/utilSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    util: utilReducer,
    messages: messageReducer,
    [userApi.reducerPath]: userApi.reducer,
    [usersRoleApi.reducerPath]: usersRoleApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [timezoneApi.reducerPath]: timezoneApi.reducer,
    [listViewApi.reducerPath]: listViewApi.reducer,
    [formViewApi.reducerPath]: formViewApi.reducer,
    [fieldSecurityLevelApi.reducerPath]: fieldSecurityLevelApi.reducer,
    [fieldSecurityLevelsRoleApi.reducerPath]:
      fieldSecurityLevelsRoleApi.reducer,
    [tableSecurityLevelApi.reducerPath]: tableSecurityLevelApi.reducer,
    [tableSecurityLevelsRoleApi.reducerPath]:
      tableSecurityLevelsRoleApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [titleApi.reducerPath]: titleApi.reducer,
    [rateCardApi.reducerPath]: rateCardApi.reducer,
    [rateApi.reducerPath]: rateApi.reducer,
    [numberMaintenanceApi.reducerPath]: numberMaintenanceApi.reducer,
    [timeSheetApi.reducerPath]: timeSheetApi.reducer,
    [holidayApi.reducerPath]: holidayApi.reducer,
    [pivotFilterApi.reducerPath]: pivotFilterApi.reducer,
    [impersonateApi.reducerPath]: impersonateApi.reducer,
    [reportingApi.reducerPath]: reportingApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    [securityLevelApi.reducerPath]: securityLevelApi.reducer,
    [tableApi.reducerPath]: tableApi.reducer,
    [vacationMilestoneApi.reducerPath]: vacationMilestoneApi.reducer,
    [leaveTypeApi.reducerPath]: leaveTypeApi.reducer,
    [userVacationApi.reducerPath]: userVacationApi.reducer,
  },
  //Just something you have to add to get the API to work with the store
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(usersRoleApi.middleware)
      .concat(roleApi.middleware)
      .concat(departmentApi.middleware)
      .concat(authApi.middleware)
      .concat(timezoneApi.middleware)
      .concat(listViewApi.middleware)
      .concat(formViewApi.middleware)
      .concat(fieldSecurityLevelApi.middleware)
      .concat(fieldSecurityLevelsRoleApi.middleware)
      .concat(tableSecurityLevelApi.middleware)
      .concat(tableSecurityLevelsRoleApi.middleware)
      .concat(clientApi.middleware)
      .concat(projectApi.middleware)
      .concat(taskApi.middleware)
      .concat(titleApi.middleware)
      .concat(rateCardApi.middleware)
      .concat(rateApi.middleware)
      .concat(numberMaintenanceApi.middleware)
      .concat(timeSheetApi.middleware)
      .concat(holidayApi.middleware)
      .concat(pivotFilterApi.middleware)
      .concat(impersonateApi.middleware)
      .concat(reportingApi.middleware)
      .concat(ticketApi.middleware)
      .concat(securityLevelApi.middleware)
      .concat(tableApi.middleware)
      .concat(vacationMilestoneApi.middleware)
      .concat(leaveTypeApi.middleware)
      .concat(userVacationApi.middleware),

  devTools: false,
});

setupListeners(store.dispatch);

export { store, setCredentials, reset, toggleLeftNavCollapse };
export {
  useFetchAuthQuery,
  useFetchSecretQuery,
  useRegisterUserMutation,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useFetchResetTokenQuery,
  useResetPasswordMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation,
  useVerifyCodeMutation,
} from "./apis/authApi";
export {
  useFetchUsersQuery,
  useFetchUserByIdQuery,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation,
  useCreateUserMutation,
  useEmailUserInviteMutation,
  useResetUserPasswordMutation,
  useUserExportListQuery,
} from "./apis/userApi";
export {
  useFetchUsersRoleQuery,
  useFetchUsersRoleByIdQuery,
  useUpdateUsersRoleByIdMutation,
  useDeleteUsersRoleByIdMutation,
  useCreateUsersRoleMutation,
} from "./apis/usersRoleApi";
export {
  useFetchDepartmentsQuery,
  useFetchDepartmentByIdQuery,
  useUpdateDepartmentByIdMutation,
  useDeleteDepartmentByIdMutation,
  useCreateDepartmentMutation,
  useDepartmentExportListQuery,
} from "./apis/departmentApi";
export {
  useFetchRoleQuery,
  useFetchRoleByIdQuery,
  useUpdateRoleByIdMutation,
  useDeleteRoleByIdMutation,
  useCreateRoleMutation,
  useResetRoleByIdMutation,
} from "./apis/roleApi";
export { useFetchTimezoneQuery } from "./apis/timezoneApi";
export {
  useFetchListViewQuery,
  useFetchListViewByIdQuery,
  useUpdateListViewByIdMutation,
  useFetchListViewByTableQuery,
  useListViewExportListQuery,
} from "./apis/listViewApi";
export {
  useFetchFormViewQuery,
  useFetchFormViewByIdQuery,
  useUpdateFormViewByIdMutation,
  useFetchFormViewByTableQuery,
  useFormViewExportListQuery,
} from "./apis/formViewApi";
export {
  useFetchFieldSecurityLevelQuery,
  useFetchFieldSecurityLevelByIdQuery,
  useUpdateFieldSecurityLevelByIdMutation,
  useFieldSecurityLevelExportListQuery,
} from "./apis/fieldSecurityLevelApi";
export {
  useFetchFieldSecurityLevelsRoleQuery,
  useFetchFieldSecurityLevelsRoleByIdQuery,
  useUpdateFieldSecurityLevelsRoleByIdMutation,
  useDeleteFieldSecurityLevelsRoleByIdMutation,
  useCreateFieldSecurityLevelsRoleMutation,
} from "./apis/fieldSecurityLevelsRoleApi";
export {
  useFetchTableSecurityLevelQuery,
  useFetchTableSecurityLevelByIdQuery,
  useUpdateTableSecurityLevelByIdMutation,
  useTableSecurityLevelExportListQuery,
} from "./apis/tableSecurityLevelApi";
export {
  useFetchTableSecurityLevelsRoleQuery,
  useFetchTableSecurityLevelsRoleByIdQuery,
  useUpdateTableSecurityLevelsRoleByIdMutation,
  useDeleteTableSecurityLevelsRoleByIdMutation,
  useCreateTableSecurityLevelsRoleMutation,
} from "./apis/tableSecurityLevelsRoleApi";
export {
  useFetchClientsQuery,
  useFetchClientByIdQuery,
  useUpdateClientByIdMutation,
  useDeleteClientByIdMutation,
  useCreateClientMutation,
  useClientExportListQuery,
} from "./apis/clientApi";
export {
  useFetchProjectsQuery,
  useFetchProjectByIdQuery,
  useUpdateProjectByIdMutation,
  useDeleteProjectByIdMutation,
  useCreateProjectMutation,
  useProjectExportListQuery,
} from "./apis/projectApi";
export {
  useFetchTasksQuery,
  useFetchTaskByIdQuery,
  useUpdateTaskByIdMutation,
  useDeleteTaskByIdMutation,
  useCreateTaskMutation,
  useTaskExportListQuery,
} from "./apis/taskApi";
export {
  useFetchTitlesQuery,
  useFetchTitleByIdQuery,
  useUpdateTitleByIdMutation,
  useDeleteTitleByIdMutation,
  useCreateTitleMutation,
  useTitleExportListQuery,
} from "./apis/titleApi";
export {
  useFetchRateCardsQuery,
  useFetchRateCardByIdQuery,
  useUpdateRateCardByIdMutation,
  useDeleteRateCardByIdMutation,
  useCreateRateCardMutation,
  useRateCardExportListQuery,
} from "./apis/rateCardApi";
export {
  useFetchRatesQuery,
  useFetchRateByIdQuery,
  useUpdateRateByIdMutation,
  useDeleteRateByIdMutation,
  useCreateRateMutation,
  useRateExportListQuery,
} from "./apis/rateApi";
export {
  useFetchNumberMaintenancesQuery,
  useFetchNumberMaintenanceByIdQuery,
  useUpdateNumberMaintenanceByIdMutation,
  useDeleteNumberMaintenanceByIdMutation,
  useCreateNumberMaintenanceMutation,
  useNumberMaintenanceExportListQuery,
} from "./apis/numberMaintenanceApi";
export {
  useFetchTimeSheetsQuery,
  useFetchTimeSheetByIdQuery,
  useUpdateTimeSheetByIdMutation,
  useDeleteTimeSheetByIdMutation,
  useCreateTimeSheetMutation,
  useTimeSheetExportListQuery,
} from "./apis/timeSheetApi";
export {
  useFetchHolidaysQuery,
  useFetchHolidayByIdQuery,
  useUpdateHolidayByIdMutation,
  useDeleteHolidayByIdMutation,
  useCreateHolidayMutation,
  useHolidayExportListQuery,
} from "./apis/holidayApi";
export {
  useFetchPivotFiltersQuery,
  useFetchPivotFilterByIdQuery,
  useUpdatePivotFilterByIdMutation,
  useDeletePivotFilterByIdMutation,
  useCreatePivotFilterMutation,
  useRunPivotFilterMutation,
} from "./apis/pivotFilterApi";
export {
  useFetchImpersonateUsersQuery,
  useImpersonateMutation,
} from "./apis/impersonateApi";
export { useFetchHomePageDataQuery } from "./apis/reportingApi";
export { useCreateTicketMutation } from "./apis/ticketApi";
export {
  useFetchSecurityLevelsQuery,
  useFetchSecurityLevelByIdQuery,
  useUpdateSecurityLevelByIdMutation,
  useDeleteSecurityLevelByIdMutation,
  useCreateSecurityLevelMutation,
  useSecurityLevelExportListQuery,
} from "./apis/securityLevelApi";
export { useFetchTableQuery } from "./apis/tableApi";
export {
  useFetchVacationMilestoneByIdQuery,
  useUpdateVacationMilestoneByIdMutation,
} from "./apis/vacationMilestoneApi";
export {
  useFetchLeaveTypesQuery,
  useFetchLeaveTypeByIdQuery,
  useUpdateLeaveTypeByIdMutation,
  useDeleteLeaveTypeByIdMutation,
  useCreateLeaveTypeMutation,
  useLeaveTypeExportListQuery,
  useFetchLeaveTypesTimeSheetQuery,
} from "./apis/leaveTypeApi";
export {
  useFetchUserVacationsQuery,
  useFetchUserVacationByIdQuery,
  useUpdateUserVacationByIdMutation,
  useUserVacationExportListQuery,
} from "./apis/userVacationApi";
