import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toggleMobileNav } from "../store/slices/utilSlice";

const LeftNavIcon = ({ imagePath, text, level, isToggle, link }) => {
  const dispatch = useDispatch();

  level = level || 1;
  let padding = 0;
  for (let i = 1; i < level; i++) {
    padding += 20;
  }
  return (
    <NavLink
      to={`/${link}`}
      className='cursor-pointer left-nav-link'
      onClick={() => {
        dispatch(toggleMobileNav(false));
      }}
    >
      <div>
        {!isToggle && <div style={{ paddingLeft: `${padding}px` }}>{text}</div>}
        {isToggle && (
          <div className='flex justify-center py-2'>
            <OverlayTrigger
              placement='right'
              overlay={<Tooltip>{text}</Tooltip>}
            >
              <img
                src={`/images/${imagePath}`}
                alt={text}
                className='h-7 w-7'
              />
            </OverlayTrigger>
          </div>
        )}
      </div>
    </NavLink>
  );
};

export default LeftNavIcon;
