import List from "../components/List";
import requireAuth from "../requireAuth";

import { securityLevelApi } from "../store/apis/securityLevelApi";
import {
  useFetchSecurityLevelsQuery,
  useSecurityLevelExportListQuery,
} from "../store";

const SecurityLevelList = () => {
  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (securityLevel) => `/securityLevel/${securityLevel._id}`,
    },
    {
      label: "Table",
      render: (securityLevel) => securityLevel?.table?.label || "",
      sortValue: "table",
      filterValue: "table",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Role",
      render: (securityLevel) => securityLevel?.role?.name,
      sortValue: "role",
      filterValue: "role",
      filterType: "String",
    },
  ];

  //What is the key for the data?
  const keyFn = (securityLevel) => {
    return securityLevel._id;
  };

  const recordNames = {
    title: "Security Level",
    formLink: "securityLevel",
    listLink: "securityLevel", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  return (
    <List
      config={config}
      keyFn={keyFn}
      listApi={securityLevelApi}
      useFetchQuery={useFetchSecurityLevelsQuery}
      useExportQuery={useSecurityLevelExportListQuery}
      recordNames={recordNames}
    ></List>
  );
};

export default requireAuth(SecurityLevelList);
