import React from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { addMessage } from "../../store/slices/messageSlice";

const VacationMilestoneCustom = ({
  state,
  field,
  handleChange,
  isDisabled,
  isLoading,
  id,
}) => {
  const dispatch = useDispatch();
  const handleCustomChange = async (e, i) => {
    let newState = cloneDeep(state[field]);

    let value = parseInt(e.target.value, 10) || 0;

    if (value > 99 && e.target.name === "endYear") {
      value = 99;
      dispatch(
        addMessage({
          message: `Maximum end year is 99. Setting the end year to 99.`,
          variant: "warning",
        })
      );
    }

    if (value > 1800 && e.target.name === "accrualRate") {
      value = 1800;
      dispatch(
        addMessage({
          message: `Maximum accrual rate is 1800. Setting the accrual rate to 1800.`,
          variant: "warning",
        })
      );
    }

    newState[i] = {
      ...newState[i],
      [e.target.name]: value,
    };

    clientScripts(newState);

    handleChange({ target: { name: field, value: newState } });
  };

  const handleAddRow = async () => {
    let newState = cloneDeep(state[field]);

    newState.push({
      _id: newState.length + 1,
      startYear: 0,
      endYear: 0,
      accrualRate: 0,
    });

    clientScripts(newState, true);
    handleChange({ target: { name: field, value: newState } });
  };

  const clientScripts = (newState, isNewRow) => {
    //First start year is always 0
    newState[0].startYear = 0;

    //start year is always 1 + the prior end year
    for (let i = 0; i < newState.length; i++) {
      if (i !== 0) {
        newState[i].startYear = newState[i - 1].endYear + 1;
      }

      //Check that the end year is greater than the start year by at least 1
      if (newState[i].endYear <= newState[i].startYear) {
        newState[i].endYear = newState[i].startYear + 1;
        if (!isNewRow)
          dispatch(
            addMessage({
              message: `End year must be greater than the start year for a milestone. Setting the end year to ${newState[i].endYear}.`,
              variant: "warning",
            })
          );
      }

      //The 2nd to last row was the last row if a new row was added. Set the end year to the start
      //year +5
      if (isNewRow && i === newState.length - 2) {
        newState[i].endYear = newState[i].startYear + 4;
      }
    }

    //last end year is always "-"
    newState[newState.length - 1].endYear = 1000;

    return;
  };

  const handleDeleteRow = (i) => {
    let newState = cloneDeep(state[field]);

    //remove the array element at index i
    newState.splice(i, 1);

    clientScripts(newState);
    handleChange({ target: { name: field, value: newState } });
  };

  const addRowIsDisabled = () => {
    if (state[field].length === 1) return false;
    return state[field][Math.max(state[field].length - 2, 0)].endYear >= 95;
  };

  const headerClasses = "text-center p-3 w-1/3 lg:w-1/4";
  const cellClasses = "py-4 lg:py-1 px-2 lg:px-8";

  const milestoneTable = (
    <table className='border-none z-1'>
      <thead>
        <tr>
          <th className={headerClasses}>Start Year</th>
          <th className={headerClasses}>End Year</th>
          <th className={headerClasses}>Accrual Hours / Year</th>
          {!isDisabled && (
            <th className={"hidden lg:table-cell p-4 w-[7.3%]"}></th>
          )}
        </tr>
      </thead>
      <tbody className='shadow-sj-btm relative z-40'>
        <tr>
          <td colSpan={4} className='p-2'></td>
        </tr>
        {state[field].map((milestone, i) => (
          <tr key={milestone._id.toString() + i.toString()} className=''>
            <td className={cellClasses}>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>Start year is always 1 + the prior end year</Tooltip>
                }
              >
                <Form.Control
                  type='number'
                  name='startYear'
                  className='text-center'
                  min={0}
                  max={isNaN(milestone.endYear) ? 99 : milestone.endYear - 1}
                  value={milestone.startYear}
                  onChange={(e) => handleCustomChange(e, i)}
                  id={id}
                  disabled={true}
                />
              </OverlayTrigger>
            </td>
            <td className={cellClasses}>
              {i !== state[field].length - 1 && (
                <Form.Control
                  type='number'
                  name='endYear'
                  className='text-center'
                  min={milestone.startYear}
                  max={99}
                  value={milestone.endYear.toString() || 0}
                  onChange={(e) => handleCustomChange(e, i)}
                  disabled={isDisabled}
                  id={id}
                />
              )}
              {i === state[field].length - 1 && (
                <span>
                  {"Over " + milestone.startYear.toString() + " years"}
                </span>
              )}
            </td>
            <td className={cellClasses}>
              <Form.Control
                type='number'
                name='accrualRate'
                className='text-center'
                min={0}
                max={1800}
                value={milestone.accrualRate.toString() || 0}
                onChange={(e) => handleCustomChange(e, i)}
                disabled={isDisabled}
                id={id}
              />
            </td>
            {!isDisabled && (
              <td className='hidden lg:flex justify-end px-0 py-2'>
                {state[field].length > 1 && (
                  <Button
                    onClick={() => {
                      handleDeleteRow(i);
                    }}
                    variant='danger'
                    className='cursor-pointer inline shadow-none 
                rounded-tr-none 
                rounded-br-none
                hover:rounded-tr-none
                hover:rounded-br-none
                hover:shadow-none
                '
                  >
                    <img
                      src='/images/X.svg'
                      alt='X'
                      className='w-4 ml-2 mt-1'
                    />
                  </Button>
                )}
              </td>
            )}
          </tr>
        ))}
        <tr>
          <td colSpan={4} className='p-2'></td>
        </tr>
      </tbody>
      <tfoot>
        <tr className='bg-grey-100'>
          {!isDisabled && (
            <td colSpan={4} className='text-left rounded-b-sj p-3'>
              <OverlayTrigger
                placement='top'
                overlay={
                  addRowIsDisabled() ? (
                    <Tooltip>
                      The end year of the second to last row must be less than
                      95 to add a new row.
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div style={{ display: "inline-block" }}>
                  <Button
                    onClick={handleAddRow}
                    variant='primary'
                    disabled={addRowIsDisabled()}
                  >
                    Add Milestone
                  </Button>
                </div>
              </OverlayTrigger>
            </td>
          )}
        </tr>
      </tfoot>
    </table>
  );

  return (
    <div className={`flex items-center justify-center grow my-8 w-full`}>
      <div
        className={`w-[90%] lg:w-[80%] z-40 relative shadow-sj  rounded-sj bg-[#EFEFEF] flex-col justify-center`}
      >
        <div className='relative shadow-sj-btm rounded-t-sj py-8'>
          <div className='font-bold text-xl text-center pb-4'>
            Vacation Milestones
          </div>
          <div className='text-md lg:text-lg text-center px-8'>
            Milestones are used to determine how much Vacation an employee
            accrues based on their tenure.{" "}
            <span className='text-primary-light'>
              Making changes to milestones will cause all users' vacation to be
              recalculated (overnight).
            </span>
          </div>
        </div>

        <div className='flex justify-center w-full'>
          {!isLoading && milestoneTable}
        </div>
      </div>
    </div>
  );
};

export default VacationMilestoneCustom;
