import React from "react";
import { Form } from "react-bootstrap";

const SecurityLevelTableAccess = ({
  state,
  field,
  handleChange,
  isDisabled,
  isLoading,
  id,
}) => {
  const handleCustomChange = (e) => {
    const newState = { ...state[field], [e.target.name]: e.target.checked };
    handleChange({ target: { name: field, value: newState } });
  };

  const availablePermissions = [
    { name: "create", label: "Create" },
    { name: "read", label: "Read" },
    { name: "update", label: "Update" },
    { name: "delete", label: "Delete" },
    { name: "leftnav", label: "Laft Nav" },
  ];

  const checkBoxes = availablePermissions.map((item) => {
    const data = state[field];

    if (data.hasOwnProperty(item.name) === false) {
      return null;
    }

    return (
      <Form.Group
        className='mx-4 md:mx-0 flex align-items-center px-4 my-2'
        controlId={item.name}
        key={item.name}
      >
        <div className='flex lg:flex-col justify-center items-center mb-4 w-full lg:w-auto'>
          <span className='mb-2 text-xl mx-4 lg:mx-0 pt-2 w-[100px] lg:w-auto'>
            {item.label}
          </span>
          <Form.Check
            disabled={isDisabled}
            type='checkbox'
            // label={item.label}
            name={item.name}
            checked={state[field][item.name] || false}
            onChange={(e) => {
              handleCustomChange(e);
            }}
            // onChange={(e) =>
            //   setState({ ...state, [item.field]: e.target.checked })
            // }
          ></Form.Check>
        </div>
      </Form.Group>
    );
  });

  const header = (
    <>
      <div className='font-bold text-2xl text-center my-4'>Table Access</div>
      <div className='text-md lg:text-lg mb-4 px-8'>
        Users with the <span className='text-primary-light'>{state.role}</span>{" "}
        role will be able to do the following with{" "}
        <span className='text-primary-light'>{state.table}</span> records.
      </div>
    </>
  );

  const newRecordHeader = (
    <>
      <div className='text-lg lg:text-xl mt-2 px-8'>
        Save this record to adjust the Table and Field access levels
      </div>
    </>
  );

  return (
    <div className={`flex items-center justify-center grow my-2 w-full`}>
      <div
        className={`w-[80%]  z-40 px-1 md:px-3 my-5 relative shadow-sj rounded-sj bg-[#EFEFEF]`}
      >
        <div className='flex justify-center w-full my-4'>
          <div className='flex-col justify-center'>
            {id === "-1" ? newRecordHeader : header}
          </div>
        </div>
        <div className='flex justify-center w-full my-4'>
          <div className='flex flex-col lg:flex-row justify-between w-[75%] '>
            {!isLoading && checkBoxes}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityLevelTableAccess;
