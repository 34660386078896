import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import CloseButton from "../CloseButton";
import ReferenceField from "../ReferenceField";

import { useFetchClientsQuery } from "../../store";
import { clientApi } from "../../store/apis/clientApi";

import { useFetchProjectsQuery } from "../../store";
import { projectApi } from "../../store/apis/projectApi";

import { useFetchTasksQuery } from "../../store";
import { taskApi } from "../../store/apis/taskApi";

import { useFetchLeaveTypesTimeSheetQuery } from "../../store";
import { leaveTypeApi } from "../../store/apis/leaveTypeApi";

import { Button } from "react-bootstrap";
import * as Yup from "yup";

function TimeEntryModal({
  onClose,
  actionBar,
  children,
  title,
  timeSheetState,
  handleCreateNewTimeEntry,
}) {
  const [isWorkingTime, setIsWorkingTime] = useState(true);
  const [state, setState] = useState({
    client: "",
    project: "",
    task: "",
    leaveType: "",
  });
  const [stateID, setStateID] = useState({
    client: "",
    project: "",
    task: "",
    leaveType: "",
  });

  const [stateObj, setStateObj] = useState({
    client: {},
    project: {},
    task: {},
    leaveType: {},
  });
  const [errors, setErrors] = useState({});

  let dTaskFilter =
    "active=True^allowTimeEntry=True^project.active=True^project.allowTimeEntry=True^";
  for (let i = 0; i < timeSheetState.timeEntries.length; i++) {
    const timeEntry = timeSheetState.timeEntries[i];

    if (timeEntry.task) {
      dTaskFilter += `_id!=${timeEntry.task._id}^`;
    }
  }

  //This idea is that if the value changes then the dependices state and stateID should be reset
  //the other 2 values should be cleared on each change and then dependices brough in
  const defaultFilterObj = {
    client: {
      defaultFilter: "",
      dependeciesUp: [],
      dependeciesDown: ["project", "task"],
    },
    project: {
      defaultFilter: "active=True^allowTimeEntry=True^",
      dependeciesUp: ["client"],
      dependeciesDown: ["task"],
    },
    task: {
      defaultFilter: dTaskFilter, //_id!=64f2580e1af8a9d30c3bc105^
      dependeciesUp: ["client", "project"],
      dependeciesDown: [],
    },
  };

  const [defaultClientFilter, setDefaultClientFilter] = useState(
    defaultFilterObj.client.defaultFilter
  );
  const [defaultProjectFilter, setDefaultProjectFilter] = useState(
    defaultFilterObj.project.defaultFilter
  );
  const [defaultTaskFilter, setDefaultTaskFilter] = useState(
    defaultFilterObj.task.defaultFilter
  );

  defaultFilterObj.client.setFilter = setDefaultClientFilter;
  defaultFilterObj.project.setFilter = setDefaultProjectFilter;
  defaultFilterObj.task.setFilter = setDefaultTaskFilter;

  //Handle Create
  const handleCreate = async () => {
    let timeEntry = {};
    if (isWorkingTime) {
      try {
        await validationSchema.validate({ ...stateID }, { abortEarly: false });
        setErrors({});
      } catch (err) {
        if (err.inner) {
          const validationErrors = {};
          err.inner.forEach((e) => {
            validationErrors[e.path] = e.message;
          });
          setErrors(validationErrors);
          return;
        }
      }

      timeEntry = {
        _id: `-1:${stateID.task}`,
        task: {
          _id: stateID.task,
          active: true,
          allowTimeEntry: true,
          name: state.task,
          number: stateObj.task?.number,
          startDate: stateObj.task?.startDate,
          endDate: stateObj.task?.endDate,
        },
        client: {
          _id: stateID.client,
          name: state.client,
          number: stateObj.client?.number,
        },
        project: {
          _id: stateID.project,
          active: true,
          allowTimeEntry: true,
          name: state.project,
          number: stateObj.project?.number,
          startDate: stateObj.project?.startDate,
          endDate: stateObj.project?.endDate,
        },
        isWorkingTime: isWorkingTime,
      };
    } else {
      try {
        await leaveTypeValidationSchema.validate(
          { ...stateID },
          { abortEarly: false }
        );
        setErrors({});
      } catch (err) {
        if (err.inner) {
          const validationErrors = {};
          err.inner.forEach((e) => {
            validationErrors[e.path] = e.message;
          });
          setErrors(validationErrors);
          return;
        }
      }

      timeEntry = {
        _id: `-1:${stateObj.leaveType.name}`,
        leaveType: {
          name: stateObj.leaveType.name,
          _id: stateID.leaveType,
          active: stateObj.leaveType.active,
          adminOnly: stateObj.leaveType.adminOnly,
        },
        isWorkingTime: isWorkingTime,
      };
    }

    handleCreateNewTimeEntry(timeEntry);
    onClose();
  };

  //Clear Selections
  const handleClear = () => {
    setState({ client: "", project: "", task: "" });
    setStateID({ client: "", project: "", task: "" });
    setStateObj({ client: {}, project: {}, task: {} });
    setDefaultClientFilter(defaultFilterObj.client.defaultFilter);
    setDefaultProjectFilter(defaultFilterObj.project.defaultFilter);
    setDefaultTaskFilter(defaultFilterObj.task.defaultFilter);
    setErrors({});
  };

  //Ref Field changes
  const handleRefSelect = (newState, newStateID, record) => {
    //Get the key of the new state i.e., client, project, task
    const key = Object.keys(newState)[0];

    if (!record) {
      setState({ ...state, ...newState });
      setStateID({ ...stateID, ...newStateID });
      return;
    }

    const newStateObj = { [key]: record };
    // Regex to check if it is a mongoid
    const regex = /^[0-9a-fA-F]{24}$/;

    // test if newstateid is a mongoid
    if (regex.test(newStateID[key]) && key !== "leaveType") {
      //For anything that is a dependecy of the new state clear the state and stateID and set the default filter with the sysid
      for (let i = 0; i < defaultFilterObj[key].dependeciesDown.length; i++) {
        //dependecyDown = ["project", "task"]
        const dependecy = defaultFilterObj[key].dependeciesDown[i];

        newState[dependecy] = "";
        newStateID[dependecy] = "";
        newStateObj[dependecy] = {};

        //Set the default filter for the dependecy
        //if the dependey down is the task and the key is the clien i need to dotwalk to the client

        if (dependecy === "task" && key === "client") {
          defaultFilterObj[dependecy].setFilter(
            defaultFilterObj[dependecy].defaultFilter +
              `project.client._id=${newStateID[key]}^`
          );
        } else {
          defaultFilterObj[dependecy].setFilter(
            defaultFilterObj[dependecy].defaultFilter +
              `${key}._id=${newStateID[key]}^`
          );
        }
      }

      //For the dependecies up set the state and state id to the new values
      for (let i = 0; i < defaultFilterObj[key].dependeciesUp.length; i++) {
        //dependecyUp = ["project", "client"]
        const dependecy = defaultFilterObj[key].dependeciesUp[i];

        if (key === "task" && dependecy === "client") {
          newStateObj["client"] = record.project.client;
          newState["client"] = record.project.client.name;
          newStateID["client"] = record.project.client._id;
        } else {
          newStateObj[dependecy] = record[dependecy];
          newState[dependecy] = record[dependecy].name;
          newStateID[dependecy] = record[dependecy]._id;
        }

        defaultFilterObj[dependecy].setFilter(
          defaultFilterObj[dependecy].defaultFilter
        );
      }
    }

    // setStateNumber({ ...stateNumber, ...newStateNumber });
    setStateObj({ ...stateObj, ...newStateObj });
    setState({ ...state, ...newState });
    setStateID({ ...stateID, ...newStateID });
  };

  const clientRefConfig = {
    label: stateObj.client.number
      ? `Client - ${stateObj.client?.number}`
      : "Client",
    field: "client",
    defaultValue: "",
    inputType: "reference",
    refDisplayField: "name",
    render: (data) => data?.client?.name || "",
    id: (data) => data?.client?._id || "",
    useFetchQuery: useFetchClientsQuery, //This is the api for getting the search results for the ref field dropdown and modal
    validation: Yup.string()
      .matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid client",
        excludeEmptyString: true,
      })
      .required("Client is required"),
    //This is used to populate the modal list when you click the magnifying glass
    refModalListConfig: {
      config: [
        {
          label: "Name",
          render: (client) => client.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String", //This is only used for the comparison logic on the front end.
        },
        {
          label: "Number",
          render: (client) => client.number,
          sortValue: "number",
          // render: (client) => <div className={`p-3 m-2 ${client.color}`}></div>,
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Client ID", //header label
          render: (client) => client.clientId, //How to render the cell
          sortValue: "clientId",
          filterValue: "clientId",
          filterType: "String",
        },
      ],
      keyFn: (client) => {
        return client._id;
      },
      recordNames: {
        title: "Select a Client",
        formLink: "client",
        listLink: "clients",
      },
      listApi: clientApi,
      useFetchQuery: useFetchClientsQuery,
    },
  };

  const projectRefConfig = {
    label: stateObj.project.number
      ? `Project - ${stateObj.project.number}`
      : "Project",
    field: "project",
    defaultValue: "",
    inputType: "reference",
    refDisplayField: "name",
    render: (data) => {
      let myProject = "";
      if (data.project) {
        myProject = data.project?.number + " - " + data.project?.name;
      }
      return myProject;
    },
    id: (data) => data?.project?._id || "",
    useFetchQuery: useFetchProjectsQuery, //This is the api for getting the search results for the ref field dropdown and modal
    validation: Yup.string()
      .matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid project",
        excludeEmptyString: true,
      })
      .required("Project is required"),
    //This is used to populate the modal list when you click the magnifying glass
    refModalListConfig: {
      config: [
        {
          label: "Number",
          render: (project) => project.number,
          sortValue: "number",
          // render: (project) => <div className={`p-3 m-2 ${project.color}`}></div>,
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Name",
          render: (project) => project.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String", //This is only used for the comparison logic on the front end.
        },

        {
          label: "Status", //header label
          render: (project) => project.status, //How to render the cell
          sortValue: "status",
          filterValue: "status",
          filterType: "String",
        },
        {
          label: "Client Name",
          render: (project) => project?.client?.name,
          sortValue: "client",
          filterValue: "client",
          filterType: "String",
        },
      ],
      keyFn: (project) => {
        return project._id;
      },
      recordNames: {
        title: "Select a Project",
        formLink: "project",
        listLink: "projects",
      },
      listApi: projectApi,
      useFetchQuery: useFetchProjectsQuery,
    },
  };

  const taskRefConfig = {
    label: stateObj.task.number ? `Task - ${stateObj.task.number}` : "Task",
    field: "task",
    defaultValue: "",
    inputType: "reference",
    refDisplayField: "name",
    render: (data) => {
      let myTask = "";
      if (data.task) {
        myTask = data.task?.number + " - " + data.task?.name;
      }
      return myTask;
    },
    id: (data) => data?.task?._id || "",
    useFetchQuery: useFetchTasksQuery, //This is the api for getting the search results for the ref field dropdown and modal
    validation: Yup.string()
      .matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid task",
        excludeEmptyString: true,
      })
      .required("Task is required"),
    //This is used to populate the modal list when you click the magnifying glass
    refModalListConfig: {
      config: [
        {
          label: "Number",
          render: (task) => task.number,
          sortValue: "number",
          // render: (task) => <div className={`p-3 m-2 ${task.color}`}></div>,
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Name",
          render: (task) => task.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String", //This is only used for the comparison logic on the front end.
        },
        {
          label: "Status", //header label
          render: (task) => task.status, //How to render the cell
          sortValue: "status",
          filterValue: "status",
          filterType: "String",
        },
        {
          label: "Project Number",
          render: (task) => task?.project?.number,
          sortValue: "project",
          filterValue: "project",
          filterType: "String",
        },
      ],
      keyFn: (task) => {
        return task._id;
      },
      recordNames: {
        title: "Select a Task",
        formLink: "task",
        listLink: "tasks",
      },
      listApi: taskApi,
      useFetchQuery: useFetchTasksQuery,
    },
  };

  const leaveTypeConfig = {
    label: "Leave Type",
    field: "leaveType",
    defaultValue: "",
    inputType: "reference",
    refDisplayField: "name",
    render: (data) => data?.leaveType?.name || "",
    id: (data) => data?.leaveType?._id || "",
    useFetchQuery: useFetchLeaveTypesTimeSheetQuery, //This is the api for getting the search results for the ref field dropdown and modal
    validation: Yup.string()
      .matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid leave type",
        excludeEmptyString: true,
      })
      .required("Leave Type is required"),
    //This is used to populate the modal list when you click the magnifying glass
    refModalListConfig: {
      config: [
        {
          label: "Name",
          render: (leaveType) => leaveType.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
      ],
      keyFn: (leaveType) => {
        return leaveType._id;
      },
      recordNames: {
        title: "Select a Leave Type",
        formLink: "leaveType",
        listLink: "leaveTypes",
      },
      listApi: leaveTypeApi,
      useFetchQuery: useFetchLeaveTypesTimeSheetQuery,
    },
  };

  const validationSchema = Yup.object().shape({
    client: clientRefConfig.validation,
    project: projectRefConfig.validation,
    task: taskRefConfig.validation,
  });

  const leaveTypeValidationSchema = Yup.object().shape({
    leaveType: leaveTypeConfig.validation,
  });

  const workingTimeContent = (
    <>
      <div className='w-full flex justify-center'>
        <ReferenceField
          key={clientRefConfig.field}
          item={clientRefConfig}
          canUpdate={!clientRefConfig.isDisabled}
          errors={errors}
          value={state[clientRefConfig.field]}
          valueID={stateID[clientRefConfig.field]}
          handleRefSelect={handleRefSelect}
          useFetchQuery={clientRefConfig.useFetchQuery}
          refModalListConfig={clientRefConfig.refModalListConfig}
          columns={"8"}
          hideIcon={true}
          defaultFilter={defaultClientFilter} //`_id=64f257ff1af8a9d30c3bbfd0^`
        ></ReferenceField>
      </div>
      <div className='w-full flex justify-center'>
        <ReferenceField
          key={projectRefConfig.field}
          item={projectRefConfig}
          canUpdate={!projectRefConfig.isDisabled}
          errors={errors}
          value={state[projectRefConfig.field]}
          valueID={stateID[projectRefConfig.field]}
          handleRefSelect={handleRefSelect}
          useFetchQuery={projectRefConfig.useFetchQuery}
          refModalListConfig={projectRefConfig.refModalListConfig}
          columns={"8"}
          hideIcon={true}
          defaultFilter={defaultProjectFilter}
        ></ReferenceField>
      </div>
      <div className='w-full flex justify-center'>
        <ReferenceField
          key={taskRefConfig.field}
          item={taskRefConfig}
          canUpdate={!taskRefConfig.isDisabled}
          errors={errors}
          value={state[taskRefConfig.field]}
          valueID={stateID[taskRefConfig.field]}
          handleRefSelect={handleRefSelect}
          useFetchQuery={taskRefConfig.useFetchQuery}
          refModalListConfig={taskRefConfig.refModalListConfig}
          columns={"8"}
          hideIcon={true}
          defaultFilter={defaultTaskFilter} //"project.client._id=64f257ff1af8a9d30c3bbfd0^"
        ></ReferenceField>
      </div>
    </>
  );

  // const item = {
  //   selectOptions: ["Vacation", "Sick", "Holiday", "Jury Duty", "Other"],
  //   field: "leaveType",
  //   label: "Non-Working Time",
  // };
  const leaveTimeContent = (
    <>
      <div className='w-full flex justify-center'>
        <ReferenceField
          key={leaveTypeConfig.field}
          item={leaveTypeConfig}
          canUpdate={!leaveTypeConfig.isDisabled}
          errors={errors}
          value={state[leaveTypeConfig.field]}
          valueID={stateID[leaveTypeConfig.field]}
          handleRefSelect={handleRefSelect}
          useFetchQuery={leaveTypeConfig.useFetchQuery}
          refModalListConfig={leaveTypeConfig.refModalListConfig}
          columns={"8"}
          hideIcon={true}
          defaultFilter={""} //"project.client._id=64f257ff1af8a9d30c3bbfd0^" 'adminOnly=false^active=true'
        ></ReferenceField>
      </div>
    </>
  );

  return (
    <>
      <Modal show={true} onHide={onClose} className='rounded-2xl' size='lg'>
        <Modal.Header className='sj-bg-grey-100 flex justify-center rounded-top-2xl shadow-sj-btm z-100'>
          <Modal.Title className='font-bold text-2xl'>{title}</Modal.Title>
          <CloseButton onClose={onClose} />
        </Modal.Header>
        <Modal.Body className='sj-bg-grey-200 shadow-sj-btm z-50'>
          {timeSheetState && (
            <div className=' flex flex-col justify-center'>
              <div className='flex justify-center'>
                <strong>User</strong> : {timeSheetState?.user?.name}
              </div>
              <div className='flex flex-col lg:flex-row justify-center'>
                <div className='flex justify-center lg:pr-5'>
                  <strong> Time Sheet</strong> : {timeSheetState?.number}
                </div>
                <div className='flex justify-center lg:pl-5'>
                  <strong> Week Starts </strong>:{" "}
                  {timeSheetState?.weekStarts.toString().substring(0, 10)}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Body className='z-40 shadow-sj-btm sj-bg-grey-100'>
          <div className='w-full flex justify-center'>
            <div className='w-1/3 leading-10 mx-5 text-center'>
              <strong> Working Time</strong>
            </div>
            <div>
              {!isWorkingTime ? (
                <img
                  src='/images/Toggle=On.svg'
                  alt='Working Time'
                  className='cursor-pointer'
                  onClick={() => {
                    setIsWorkingTime(!isWorkingTime);
                  }}
                />
              ) : (
                <img
                  src='/images/Toggle=Off.svg'
                  alt='Non-Working Time'
                  className='cursor-pointer'
                  onClick={() => {
                    setIsWorkingTime(!isWorkingTime);
                  }}
                />
              )}
            </div>
            <div className='w-1/3 text-center lg:leading-10 mx-5'>
              <strong>Leave Time</strong>
            </div>
          </div>
        </Modal.Body>
        <Modal.Body className='z-30 sj-bg-C3 w-full '>
          {isWorkingTime ? workingTimeContent : leaveTimeContent}
        </Modal.Body>
        <Modal.Footer className='sj-bg-C3 rounded-bottom-2xl border-none flex grow justify-between'>
          {isWorkingTime && (
            <Button onClick={handleClear}>Clear Selections</Button>
          )}
          {!isWorkingTime && <div></div>}
          <Button variant='primary' onClick={handleCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TimeEntryModal;
