import React from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { cloneDeep } from "lodash";
// import { useDispatch } from "react-redux";
// import { addMessage } from "../../store/slices/messageSlice";

const UserVacationAdjustmentCustom = ({
  state,
  field,
  handleChange,
  isDisabled,
  isLoading,
  id,
}) => {
  //   const dispatch = useDispatch();
  const handleCustomChange = async (e, i, isCheckbox) => {
    let newState = cloneDeep(state[field]);

    let value = "";
    if (isCheckbox) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    newState[i] = {
      ...newState[i],
      [e.target.name]: value,
    };

    clientScripts(newState);

    handleChange({ target: { name: field, value: newState } });
  };

  const handleAddRow = async () => {
    let newState = cloneDeep(state[field]);

    newState.push({
      _id: newState.length + 1,
      type: "Carry Over",
      year: new Date().getFullYear(),
      proRated: false,
      hours: 40,
    });

    clientScripts(newState, true);
    handleChange({ target: { name: field, value: newState } });
  };

  const clientScripts = (newState, isNewRow) => {
    return;
  };

  const handleDeleteRow = (i) => {
    let newState = cloneDeep(state[field]);

    //remove the array element at index i
    newState.splice(i, 1);

    clientScripts(newState);
    handleChange({ target: { name: field, value: newState } });
  };

  const addRowIsDisabled = () => {
    return false;
    // if (state[field].length === 1) return false;
    // return state[field][Math.max(state[field].length - 2, 0)].endYear >= 95;
  };

  const headerClasses = "text-center p-3";
  const cellClasses = "py-4 lg:py-1 px-2 lg:px-8";

  const adjustmentTable = (
    <table className='border-none z-1'>
      <thead>
        <tr>
          <th className={`${headerClasses} w-1/4`}>Type</th>
          <th className={`${headerClasses} w-1/12`}>Pro Rated</th>
          <th className={`${headerClasses} w-1/6`}>Year</th>
          <th className={`${headerClasses} w-1/6`}>Hours</th>
          {!isDisabled && (
            <th className={"hidden lg:table-cell p-4 w-[7.3%]"}></th>
          )}
        </tr>
      </thead>
      <tbody className='shadow-sj-btm relative z-40'>
        <tr>
          <td colSpan={4} className='p-2'></td>
        </tr>
        {state[field].map((adjustment, i) => (
          <tr key={adjustment._id.toString() + i.toString()} className=''>
            <td className={cellClasses}>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>
                    The type of adjustment. This is for your reference only.
                  </Tooltip>
                }
              >
                <Form.Control
                  type='text'
                  name='type'
                  className='text-center'
                  value={adjustment.type}
                  onChange={(e) => handleCustomChange(e, i)}
                  disabled={isDisabled}
                  id={id}
                />
              </OverlayTrigger>
            </td>
            <td className={cellClasses}>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip>
                    Uncheck this to have the adjustment added at the start of
                    the calendar year.
                  </Tooltip>
                }
              >
                <Form.Check
                  type='checkbox'
                  name='proRated'
                  checked={adjustment.proRated}
                  onChange={(e) => handleCustomChange(e, i, true)}
                  disabled={isDisabled}
                  id={id}
                />
              </OverlayTrigger>
            </td>
            <td className={cellClasses}>
              <Form.Control
                type='number'
                name='year'
                className='text-center'
                min={2000}
                max={2075}
                value={adjustment.year.toString() || 0}
                onChange={(e) => handleCustomChange(e, i)}
                disabled={isDisabled}
                id={id}
              />
            </td>
            <td className={cellClasses}>
              <Form.Control
                type='number'
                name='hours'
                className='text-center'
                min={1}
                max={1800}
                value={adjustment.hours.toString() || 0}
                onChange={(e) => handleCustomChange(e, i)}
                disabled={isDisabled}
                id={id}
              />
            </td>
            {!isDisabled && (
              <td className='hidden lg:flex justify-end px-0 py-2'>
                <Button
                  onClick={() => {
                    handleDeleteRow(i);
                  }}
                  variant='danger'
                  className='cursor-pointer inline shadow-none 
                rounded-tr-none 
                rounded-br-none
                hover:rounded-tr-none
                hover:rounded-br-none
                hover:shadow-none
                '
                >
                  <img src='/images/X.svg' alt='X' className='w-4 ml-2 mt-1' />
                </Button>
              </td>
            )}
          </tr>
        ))}
        <tr>
          <td colSpan={4} className='p-2'></td>
        </tr>
      </tbody>
      <tfoot>
        <tr className='bg-grey-100'>
          {!isDisabled && (
            <td colSpan={5} className='text-left rounded-b-sj p-3'>
              <OverlayTrigger
                placement='top'
                overlay={
                  addRowIsDisabled() ? (
                    <Tooltip>Im a tooltip. Change me</Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div style={{ display: "inline-block" }}>
                  <Button
                    onClick={handleAddRow}
                    variant='primary'
                    disabled={addRowIsDisabled()}
                  >
                    Add Adjustment
                  </Button>
                </div>
              </OverlayTrigger>
            </td>
          )}
        </tr>
      </tfoot>
    </table>
  );
  return (
    <div className={`flex items-center justify-center grow my-8 w-full`}>
      <div
        className={`w-[90%] lg:w-[80%] z-40 relative shadow-sj  rounded-sj bg-[#EFEFEF] flex-col justify-center`}
      >
        <div className='relative shadow-sj-btm rounded-t-sj py-8'>
          <div className='font-bold text-xl text-center pb-4'>
            Vacation Adjustments
          </div>
          <div className='text-md lg:text-lg text-center px-8'>
            The adjustments below allow{" "}
            <span className='text-primary-light'>{state.user}</span> to recieve
            additional vacation above the company vacation milestone policy.{" "}
            <span className='text-primary-light'>
              Vacation balances will be updated after the adjustment is saved.
            </span>
          </div>
        </div>

        <div className='flex justify-center w-full'>
          {!isLoading && adjustmentTable}
        </div>
      </div>
    </div>
  );
};

export default UserVacationAdjustmentCustom;
