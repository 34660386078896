/*
  UserVacationForm > FormSJ > List (related list)

  The userVacation form just contains config files and references to APIs. 
  You dont need to change any down stream componenets unless you are adding new functionality


*/

import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchUserVacationByIdQuery,
  useUpdateUserVacationByIdMutation,
  useFetchUsersQuery,
} from "../store";
import { userVacationApi } from "../store/apis/userVacationApi";
import { userApi } from "../store/apis/userApi";
import * as Yup from "yup";

import { formViewApi } from "../store/apis/formViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";

import { Container } from "react-bootstrap";
import Loader from "../components/Loader";
import UserVacationAdjustmentCustom from "../components/customForm/UserVacationAdjustmentCustom";

const UserVacationForm = () => {
  const storeApi = {
    fetch: useFetchUserVacationByIdQuery,
    update: useUpdateUserVacationByIdMutation,
    create: () => {
      const create = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [create, { data, error, isLoading }];
    },
    delete: () => {
      const deleteById = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [deleteById, { data, error, isLoading }];
    },
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } =
    useFetchFormViewByTableQuery("userVacation");

  //Checks to see if the userVacation is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  //Get front end security from authSlice
  const [secLevel] = useSecLevel(["userVacation"]);

  const userRelatedListConfig = {
    config: [
      {
        label: "Name",
        render: (user) => user.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
    ],
    keyFn: (user) => {
      return user._id;
    },
    recordNames: {
      title: "Select a User",
      formLink: "user",
      listLink: "users",
    },
    listApi: userApi,
    useFetchQuery: useFetchUsersQuery,
  };

  // This is the config array for the fields that will be shown on the form.
  let config = [
    {
      label: "User",
      field: "user",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      col: "6",
      toolTip: (data) =>
        `Start Date: ${data.user.startDate.substring(0, 10)}` || "",
      id: (data) => data?.user?._id || "",
      render: (data) => data.user?.name || "",
      useFetchQuery: useFetchUsersQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid user",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: userRelatedListConfig,
    },
    {
      label: "YTD Hours Earned",
      field: "ytdHoursEarned",
      defaultValue: "",
      inputType: "number",
      render: (data) => data.ytdHoursEarned || 0,
    },
    {
      label: "YTD Hours Taken",
      field: "ytdHoursTaken",
      defaultValue: "",
      inputType: "number",
      render: (data) => data.ytdHoursTaken || 0,
    },

    {
      label: "YTD Hours Remaining",
      field: "ytdHoursRemaining",
      defaultValue: "",
      inputType: "number",
      render: (data) => data.ytdHoursRemaining || 0,
    },

    {
      label: "YE Hours Earned",
      field: "yeHoursEarned",
      defaultValue: "",
      inputType: "number",
      render: (data) => data.yeHoursEarned || 0,
    },

    {
      label: "YE Hours Taken",
      field: "yeHoursTaken",
      defaultValue: "",
      inputType: "number",
      render: (data) => data.yeHoursTaken || 0,
    },

    {
      label: "YE Hours Remaining",
      field: "yeHoursRemaining",
      defaultValue: "",
      inputType: "number",
      render: (data) => data.yeHoursRemaining || 0,
    },
    {
      label: "Vacation Milestone",
      field: "adjustments",
      defaultValue: "",
      inputType: "custom",
      customComponent: {
        component: UserVacationAdjustmentCustom,
        props: { prop1: "value1" },
      },
    },
  ];

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={userVacationApi}
        config={finalConfig}
        validationSchema={validationSchema}
        secLevel={secLevel}
        formTitle='User Vacation'
        table='userVacation'
        redirect='userVacation'
        relatedListConfig={[]}
      />
    ))
  );
};

export default requireAuth(UserVacationForm);
