import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchTaskByIdQuery,
  useUpdateTaskByIdMutation,
  useDeleteTaskByIdMutation,
  useCreateTaskMutation,
  useFetchProjectsQuery,
  useFetchRateCardsQuery,
} from "../store";
import { taskApi } from "../store/apis/taskApi";
import { projectApi } from "../store/apis/projectApi";
import { formViewApi } from "../store/apis/formViewApi";
import { rateCardApi } from "../store/apis/rateCardApi";

import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

const TaskForm = () => {
  const storeApi = {
    fetch: useFetchTaskByIdQuery,
    update: useUpdateTaskByIdMutation,
    delete: useDeleteTaskByIdMutation,
    create: useCreateTaskMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchFormViewByTableQuery("task");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel] = useSecLevel(["task"]);

  const config = [
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Status",
      field: "status",
      defaultValue: "",
      inputType: "select",
      render: (data) => data.status,
      selectOptions: ["Draft", "Open", "On Hold", "Closed"],
      validation: Yup.string()
        .required("Status is required")
        .oneOf(
          ["Draft", "Open", "On Hold", "Closed"],
          "Status must be one of Draft, Open, On Hold, or Closed"
        ),
    },
    {
      label: "Project",
      field: "project",
      defaultValue: "",
      handleIsDisabled: (id, data) => id !== "-1",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => {
        let myProject = "";
        if (data.project) {
          myProject = data.project?.number + " - " + data.project?.name;
        }
        return myProject;
      },
      id: (data) => data?.project?._id || "",
      useFetchQuery: useFetchProjectsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid project",
          excludeEmptyString: true,
        })
        .required("Project is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Number",
            render: (project) => project.number,
            sortValue: "number",
            // render: (project) => <div className={`p-3 m-2 ${project.color}`}></div>,
            filterValue: "number",
            filterType: "String",
          },
          {
            label: "Name",
            render: (project) => project.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },

          {
            label: "Status", //header label
            render: (project) => project.status, //How to render the cell
            sortValue: "status",
            filterValue: "status",
            filterType: "String",
          },
        ],
        keyFn: (project) => {
          return project._id;
        },
        recordNames: {
          title: "Select a Project",
          formLink: "project",
          listLink: "projects",
        },
        listApi: projectApi,
        useFetchQuery: useFetchProjectsQuery,
      },
    },
    {
      label: "Task Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Task name must be at least 2 characters")
        .required("Task name is required"),
    },

    {
      label: "Start Date",
      field: "startDate",
      defaultValue: "",
      inputType: "date",
      render: (data) => {
        if (data.startDate) {
          return data?.startDate.toString().substring(0, 10);
        } else {
          return "";
        }
      },
      validation: Yup.date()
        .min(new Date(1900, 0, 1), "Date cannot be before the year 1900.")
        .max(new Date(2100, 0, 1), "Date cannot be after the year 2100.")
        .required("Start date is required")
        .max(Yup.ref("endDate"), "Start date must be before end date")
        .nullable()
        .typeError("Start date is required"),
    },
    {
      label: "End Date",
      field: "endDate",
      defaultValue: "",
      inputType: "date",
      render: (data) => {
        if (data.endDate) {
          return data?.endDate.toString().substring(0, 10);
        } else {
          return "";
        }
      },
      validation: Yup.date()
        .min(new Date(1900, 0, 1), "Date cannot be before the year 1900.")
        .max(new Date(2100, 0, 1), "Date cannot be after the year 2100.")
        .required("Start date is required")
        .min(Yup.ref("startDate"), "End date must be after start date")
        .nullable()
        .typeError("End date is required"),
    },
    {
      label: "Description",
      field: "description",
      defaultValue: "",
      inputType: "textarea",
      col: "12",
      render: (data) => data.description,
    },
    {
      label: "Active",
      field: "active",
      render: (data) => data.active,
      defaultValue: true,
      inputType: "checkbox",
    },
    {
      label: "Allow Time Entry",
      field: "allowTimeEntry",
      render: (data) => data.allowTimeEntry,
      defaultValue: false,
      inputType: "checkbox",
    },
    {
      label: "Rate Card",
      field: "rateCard",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.rateCard?.name || "",
      id: (data) => data?.rateCard?._id || "",
      useFetchQuery: useFetchRateCardsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string().matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid rate card",
        excludeEmptyString: true,
      }),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (rateCard) => rateCard.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Number",
            render: (rateCard) => rateCard.number,
            sortValue: "number",
            // render: (rateCard) => <div className={`p-3 m-2 ${rateCard.color}`}></div>,
            filterValue: "number",
            filterType: "String",
          },
          {
            label: "Active", //header label
            render: (rateCard) => rateCard.active.toString(), //How to render the cell
            sortValue: "active",
            filterValue: "active",
            filterType: "Boolean",
          },
        ],
        keyFn: (rateCard) => {
          return rateCard._id;
        },
        recordNames: {
          title: "Select a Rate Card",
          formLink: "rateCard",
          listLink: "rateCards",
        },
        listApi: rateCardApi,
        useFetchQuery: useFetchRateCardsQuery,
      },
    },
  ];

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={taskApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={[]}
        secLevel={secLevel}
        formTitle='Task'
        table='task'
        redirect='task'
      />
    ))
  );
};

export default requireAuth(TaskForm);
