import "../components/contentHeader.css";
import { Row, Col } from "react-bootstrap";

const ContentHeader = ({
  title,
  subtitle,
  align,
  children,
  leftHeader,
  rightHeader,
  className,
}) => {
  const alignCenterContent = (
    <div className={className + " content-header relative z-40"}>
      <Row className='w-full no-gutters'>
        <Col xs={3} sm={3} xxl='4' className='flex items-center'>
          {children} {leftHeader && leftHeader}
        </Col>
        <Col xs={6} sm={6} xxl='4' className='flex justify-center h-[85px]'>
          <div className='content-header-title w-[300px] content-header-title-center flex flex-col justify-center'>
            {!subtitle && <p className='text-xl md:text-2xl'>{title}</p>}
            {subtitle && (
              <p className='content-header-title-subtitle -translate-y-3'>
                {title}
              </p>
            )}
            {subtitle && (
              <p className='content-header-subtitle -translate-y-3'>
                {subtitle}
              </p>
            )}
          </div>
        </Col>
        {/* <Col md={3}></Col> */}
        <Col xs={3} sm={12} xxl='4' className='flex items-center'>
          {rightHeader && rightHeader}
        </Col>
      </Row>
    </div>
  );

  const alignLeftContent = (
    <div
      className={
        className +
        " content-header h-[136px] relative z-40 flex justify-between flex-col lg:flex-row lg:h-[85px]"
      }
    >
      <div className='content-header-title mr-2 lg:w-[300px]'>
        {!subtitle && <p className='leading-15'>{title}</p>}
        {subtitle && <p className='content-header-title-subtitle'>{title}</p>}
        {subtitle && <p className='content-header-subtitle'>{subtitle}</p>}
      </div>

      <div className='mx-4 flex items-center justify-between grow'>
        {children}
      </div>
    </div>
  );

  if (align === "center") {
    return alignCenterContent;
  } else {
    return alignLeftContent;
  }
};

ContentHeader.defaultProps = {
  align: "left",
};

export default ContentHeader;
