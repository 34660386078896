import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import CloseButton from "../CloseButton";
import ReferenceField from "../ReferenceField";

import { useFetchUsersQuery } from "../../store";
import { userApi } from "../../store/apis/userApi";

import { Button, Form, Col } from "react-bootstrap";
import * as Yup from "yup";

function NewTimeSheetModal({
  onClose,
  actionBar,
  children,
  title,
  handleCreateNew,
  data,
}) {
  const [state, setState] = useState({
    timeSheetUser: data?.user?.name || "",
    weekStarts: "",
  });
  const [stateID, setStateID] = useState({
    timeSheetUser: data?.user?.id || "",
  });
  const [errors, setErrors] = useState({});

  let defaultUserFilter = "active=True^";

  if (data?.user.id && !data?.isTimeSheetAdmin) {
    defaultUserFilter = `_id=${data.user.id}^`;
  }

  //do a check for admin and only show other users if they are an admin

  //Handle Create
  const handleCreate = async () => {
    let timeSheet = {};

    try {
      await validationSchema.validate(
        { ...stateID, weekStarts: state.weekStarts },
        { abortEarly: false }
      );
      setErrors({});
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
        return;
      }
    }

    timeSheet = {
      _id: "-1",
      user: stateID.timeSheetUser,
      weekStarts: state.weekStarts,
    };

    handleCreateNew(timeSheet);
    onClose();
  };

  ///Handle Change
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "weekStarts") {
      const priorMonday = getPriorMonday(new Date(value));
      setState({ ...state, [name]: priorMonday.toISOString().split("T")[0] });
    } else setState({ ...state, [name]: value });
  };

  const getPriorMonday = (date) => {
    const utcDate = new Date(date.toISOString()); // Convert to UTC

    const dayOfWeek = utcDate.getUTCDay();

    if (dayOfWeek === 1) {
      // If the current day is Monday, return the date as is
      return date;
    }
    const difference = (dayOfWeek + 6) % 7; // Calculate the difference to Monday
    const priorMonday = new Date(date);
    priorMonday.setDate(date.getDate() - difference);
    return priorMonday;
  };

  //Ref Field changes
  const handleRefSelect = (newState, newStateID) => {
    setState({ ...state, ...newState });
    setStateID({ ...stateID, ...newStateID });
  };

  const userRefConfig = {
    label: "Time Sheet User",
    field: "timeSheetUser",
    defaultValue: "",
    inputType: "reference",
    refDisplayField: "name",
    render: (data) => data?.user?.name || "",
    id: (data) => data?.user?._id || "",
    useFetchQuery: useFetchUsersQuery, //This is the api for getting the search results for the ref field dropdown and modal
    validation: Yup.string()
      .matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid user",
        excludeEmptyString: true,
      })
      .required("User is required"),
    //This is used to populate the modal list when you click the magnifying glass
    refModalListConfig: {
      config: [
        {
          label: "Name",
          render: (user) => user.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String", //This is only used for the comparison logic on the front end.
        },
      ],
      keyFn: (user) => {
        return user._id;
      },
      recordNames: {
        title: "Select a User",
        formLink: "user",
        listLink: "users",
      },
      listApi: userApi,
      useFetchQuery: useFetchUsersQuery,
    },
  };

  const validationSchema = Yup.object().shape({
    timeSheetUser: userRefConfig.validation,
    weekStarts: Yup.string().required("Week Starts On is required"),
  });

  const content = (
    <>
      <div className='w-full flex justify-center'>
        <Col md='1' sm='1' xs='1' className='flex items-center m-0 p-0'></Col>
        <ReferenceField
          key={userRefConfig.field}
          item={userRefConfig}
          canUpdate={data?.isTimeSheetAdmin}
          handleChange={handleChange}
          errors={errors}
          value={state[userRefConfig.field]}
          valueID={stateID[userRefConfig.field]}
          handleRefSelect={handleRefSelect}
          useFetchQuery={userRefConfig.useFetchQuery}
          refModalListConfig={userRefConfig.refModalListConfig}
          columns={"8"}
          hideIcon={true}
          defaultFilter={defaultUserFilter} //`_id=64f257ff1af8a9d30c3bbfd0^`
        ></ReferenceField>
      </div>
      <div className='w-full flex justify-center'>
        <Form.Group
          as={Col}
          md={"8"}
          className='my-2'
          controlId={"weekStarts"}
          key={"weekStarts"}
        >
          <Form.Label>
            Week Starts On
            {errors["weekStarts"] &&
              errors["weekStarts"]?.indexOf("required") !== -1 && (
                <span className='required'> *</span>
              )}
          </Form.Label>

          <Form.Control
            disabled={false}
            type={"date"}
            as={"input"}
            rows={1}
            col={"8"}
            placeholder={`Enter Starting Date for Time Sheet`}
            name={"weekStarts"}
            value={state["weekStarts"]}
            onChange={handleChange}
          ></Form.Control>
          {errors["weekStarts"] && (
            <span className='form-field-error'>{errors["weekStarts"]}</span>
          )}
        </Form.Group>
      </div>
    </>
  );

  return (
    <>
      <Modal show={true} onHide={onClose} className='rounded-2xl' size='lg'>
        <Modal.Header className='sj-bg-grey-100 flex justify-center rounded-top-2xl shadow-sj-btm z-100'>
          <Modal.Title className='font-bold text-2xl'>{title}</Modal.Title>
          <CloseButton onClose={onClose} />
        </Modal.Header>
        <Modal.Body className='z-30 sj-bg-C3 w-full '>{content}</Modal.Body>
        <Modal.Footer className='sj-bg-C3 rounded-bottom-2xl border-none flex grow justify-between'>
          <div></div>
          <Button variant='primary' onClick={handleCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewTimeSheetModal;
