import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const userVacationApi = createApi({
  reducerPath: "userVacations",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/userVacation`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchUserVacations: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "UserVacation" }, { type: "UserVacationList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchUserVacationById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "UserVacation" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateUserVacationById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "UserVacation" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),

      userVacationExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "UserVacation" }, { type: "UserVacationList" }];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchUserVacationsQuery,
  useFetchUserVacationByIdQuery,
  useUpdateUserVacationByIdMutation,
  useUserVacationExportListQuery,
} = userVacationApi;
export { userVacationApi };
