import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchSecurityLevelByIdQuery,
  useUpdateSecurityLevelByIdMutation,
  useDeleteSecurityLevelByIdMutation,
  useCreateSecurityLevelMutation,
  useFetchTableQuery,
  useFetchRoleQuery,
} from "../store";
import { securityLevelApi } from "../store/apis/securityLevelApi";
import SecurityLevelTableAccess from "../components/securityLevel/SecurityLevelTableAccess";
import SecurityLevelFieldAccess from "../components/securityLevel/SecurityLevelFieldAccess";

import * as Yup from "yup";
import { useSecLevel } from "../hooks/formHooks";

import { tableApi } from "../store/apis/tableApi";
import { roleApi } from "../store/apis/roleApi";

const CustomFormSJExample = () => {
  const storeApi = {
    fetch: useFetchSecurityLevelByIdQuery,
    update: useUpdateSecurityLevelByIdMutation,
    delete: useDeleteSecurityLevelByIdMutation,
    create: useCreateSecurityLevelMutation,
  };

  const [secLevel] = useSecLevel(["securityLevel"]);

  let config = [
    {
      label: "Table",
      field: "table",
      defaultValue: "",
      inputType: "reference",
      refTable: "table",
      hideIcon: true,
      refDisplayField: "label",
      render: (data) => data?.table?.label || "",
      id: (data) => data?.table?._id || "",
      useFetchQuery: useFetchTableQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Pleaes select a valid table",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Table",
            render: (data) => data?.label,
            sortValue: "label",
            filterValue: "label",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
        ],
        keyFn: (table) => {
          return table._id;
        },
        recordNames: {
          title: "Select a Table",
          formLink: "table",
          listLink: "tables",
        },
        listApi: tableApi,
        useFetchQuery: useFetchTableQuery,
      },
    },
    {
      label: "Role",
      field: "role",
      defaultValue: "",
      inputType: "reference",
      refTable: "role",
      refDisplayField: "name",
      defaultFilter: "name!=Company Admin^",
      render: (data) => data?.role?.name || "",
      id: (data) => data?.role?._id || "",
      useFetchQuery: useFetchRoleQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Pleaes select a valid role",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (role) => role.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
        ],
        keyFn: (role) => {
          return role._id;
        },
        recordNames: {
          title: "Select a Role",
          formLink: "role",
          listLink: "roles",
        },
        listApi: roleApi,
        useFetchQuery: useFetchRoleQuery,
      },
    },
    {
      label: "Table Access",
      field: "c_tableAccess",
      defaultValue: "",
      inputType: "custom",
      // customComponent: {
      //   component: FormCustomComponentExample,
      //   props: { prop1: "value1" },
      // },
      customComponent: {
        component: SecurityLevelTableAccess,
        props: { prop1: "value1" },
      },
    },
    {
      label: "Field Access",
      field: "c_fieldAccess",
      defaultValue: "",
      inputType: "custom",
      // customComponent: {
      //   component: FormCustomComponentExample,
      //   props: { prop1: "value1" },
      // },
      customComponent: {
        component: SecurityLevelFieldAccess,
        props: { prop1: "value2" },
      },
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  return (
    <FormSJ
      storeApi={storeApi}
      apiToReset={securityLevelApi}
      config={config}
      validationSchema={validationSchema}
      relatedListConfig={[]}
      secLevel={secLevel}
      formTitle='Security Level'
      table='securityLevel'
      redirect='securityLevel'
    />
  );
};

export default requireAuth(CustomFormSJExample);
