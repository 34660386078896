/**
 * ReferenceField.js
 *
 * This is the reference field that gets displayed on a form. Main features are a dropdown that searches for matching results
 * and a button that opens a modal with a list of records
 *
 * Author: Bryan Van Havryk
 * Created 5/12/23
 *
 * Changes:
 * [Date Modified] - [Short description of changes made]
 *
 */
import { Form, Col, InputGroup, Dropdown } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import ReferenceModal from "./ReferenceModal";
import List from "./List";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ReferenceField = ({
  item,
  canUpdate,
  handleRefSelect,
  value,
  valueID,
  errors,
  useFetchQuery,
  refModalListConfig,
  columns,
  hideIcon,
  defaultFilter,
}) => {
  hideIcon = hideIcon || false;
  defaultFilter = defaultFilter || "";

  const [showMenu, setShowMenu] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);

  const divEl = useRef();

  //Getting the data from RTK Query API
  const { data, isFetching } = useFetchQuery({
    page: 1,
    pageSize: 5,
    sortOrder: "asc",
    sortBy: item.refDisplayField,
    filter,
  });

  useEffect(() => {
    if (defaultFilter) {
      setFilter(defaultFilter);
    }
    // eslint-disable-next-line
  }, [defaultFilter]);

  let dropDownContent = "";

  if (data) {
    dropDownContent = data.records.map((record) => {
      return (
        <Dropdown.Item eventKey={JSON.stringify(record)} key={record._id}>
          {record[item.refDisplayField]}
        </Dropdown.Item>
      );
    });
  }

  //Handle passing the dropdown value back to the form
  const handleSelect = useCallback(
    (record) => {
      record = JSON.parse(record);
      handleRefSelect(
        { [item.field]: record[item.refDisplayField] },
        { [item.field]: record._id },
        record
      );
      setShowMenu(false);
    },
    [handleRefSelect, item.field, item.refDisplayField]
  );

  ////////////Modal Section
  const [showModal, setShowModal] = useState(false);
  const handleModalClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRowSelect = useCallback(
    (record) => {
      // console.log("record", record);
      handleSelect(JSON.stringify(record));
      handleClose();
    },
    [handleSelect]
  );

  //--Content of modal and title
  const modal = (
    <ReferenceModal onClose={handleClose}>
      {refModalListConfig && (
        <List
          handleClick={handleRowSelect}
          config={refModalListConfig.config}
          keyFn={refModalListConfig.keyFn}
          listApi={refModalListConfig.listApi}
          // useFetchQuery={refModalListConfig.useFetchQuery}
          useFetchQuery={useFetchQuery}
          recordNames={refModalListConfig.recordNames}
          defaultFilter={defaultFilter}
          // This was added to fix BUG0001 - Bug undefined on reference Dropdown.
          // I was resetting the listApi in the useCheckAuth hook but that was causing the data to be removed and return undefined
          isRefModal={true}
        ></List>
      )}
    </ReferenceModal>
  );

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  /////USE EFFECTS
  //This is used to update the filter when the user types in the input
  useEffect(() => {
    setFilter(`${defaultFilter}${item.refDisplayField}ISLIKE${value}`);
  }, [value, defaultFilter, item.refDisplayField]);

  //This adds functionality so you can click anywhere and the dropdown will close
  useEffect(() => {
    const handler = (event) => {
      //Check to make sure the elemetn was rendered
      if (!divEl.current) {
        return;
      }

      //Did the click that just happened happen inside the divEl reference that we care abount?
      if (!divEl.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    //Watch for clicks on the entire document on the capture phase
    document.addEventListener("click", handler, true);

    //This function will be called at the start of the next useEffect call
    return () => {
      document.removeEventListener("click", handler);
    };
  }, [showModal]);

  // console.log(isFetching);

  return (
    <>
      {showModal && modal}
      <Dropdown
        show={showMenu}
        onSelect={handleSelect}
        as={Col}
        md={columns || 5}
        sm={10}
        xs={10}
        className='ml-4 my-2 md:ml-0'
      >
        <Form.Group
          controlId={item.field}
          key={item.field}
          aria-haspopup='true'
          aria-expanded={showMenu}
        >
          <Form.Label>
            {item.label}
            {errors[item.field] &&
              errors[item.field]?.indexOf("required") !== -1 && (
                <span className='required'> *</span>
              )}
          </Form.Label>
          <InputGroup>
            <OverlayTrigger
              placement='top'
              overlay={
                (item.toolTip && <Tooltip>{item?.toolTip}</Tooltip>) || <></>
              }
            >
              <Form.Control
                onClick={handleToggle}
                disabled={!canUpdate}
                type='text'
                placeholder={`Enter ${item.label}`}
                name={item.field}
                value={value}
                autoComplete='off'
                onChange={(e) => {
                  // handleChange(e);
                  // if (e.target.value === "")
                  // handleUpdateFilter(e);
                  handleRefSelect(
                    { [item.field]: e.target.value },
                    { [item.field]: e.target.value }
                  );

                  if (!showMenu) setShowMenu(true);
                  // setFilter(`${item.refDisplayField}ISLIKE${e.target.value}`);
                }}
              ></Form.Control>
            </OverlayTrigger>
            {canUpdate && (
              <InputGroup.Text
                id='mag-glass'
                className='sj-bg-btn-primary cursor-pointer shadow-sj rounded-tr-2xl rounded-br-2xl'
                onClick={handleModalClick}
              >
                <img src='/images/MagnifyingGlass.svg' alt='filter' />
              </InputGroup.Text>
            )}
          </InputGroup>
          {errors[item.field] && (
            <span className='form-field-error'>{errors[item.field]}</span>
          )}
        </Form.Group>
        <Dropdown.Menu
          ref={divEl}
          className='w-2-3 my-1 shadow-sj border-none rounded-tl-2xl rounded-bl-2xl rounded-br-2xl rounded-tr-none py-0'
          style={{ width: "86%" }}
        >
          <Dropdown.ItemText className='sj-bg-grey-500 rounded-tl-2xl text-white text-center'>
            {isFetching && "Loading..."}
            {!isFetching &&
              data &&
              data.records.length > 0 &&
              `Showing ${data.records.length} of ${data.rowCount} matches`}
          </Dropdown.ItemText>
          {data && data.records.length === 0 && (
            <Dropdown.ItemText className='rounded-bl-2xl rounded-br-2xl text-center'>
              No Matches Found
            </Dropdown.ItemText>
          )}
          {data && data.records.length > 0 && dropDownContent}
          {/* <Dropdown.Item eventKey='option-1'>Option 1</Dropdown.Item>
        <Dropdown.Item eventKey='option-2'>Option 2</Dropdown.Item>
        <Dropdown.Item eventKey='option-3'>Option 3</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>

      <Col md='1' sm='1' xs='1' className='flex items-center m-0 p-0'>
        {valueID && !hideIcon && (
          <NavLink
            to={`/${refModalListConfig.recordNames.formLink}/${valueID}`}
            className='cursor-pointer'
            style={{ width: "40px", height: "40px", marginTop: "35px" }}
          >
            <img src='/images/iShadow.svg' alt='i' />
          </NavLink>
        )}
      </Col>
    </>
  );
};

export default ReferenceField;
