import React from "react";
import { Col } from "react-bootstrap";

const Card = ({ columns, isPlaceholder, children }) => {
  let className = !isPlaceholder
    ? "pb-3 rounded-sj border-solid border border-neutral-200 shadow-sj my-3 mx-7 bg-off-white"
    : "my-3 mx-7 ";

  return (
    <Col lg={columns} md={11} sm={11} xs={11} className={className}>
      <div>{children}</div>
    </Col>
  );
};

export default Card;
