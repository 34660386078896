import React, { Fragment } from "react";
import requireAuth from "../requireAuth";
import ContentHeader from "../components/ContentHeader";
import { Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState, useCallback, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useFetchPivotFilterByIdQuery,
  useUpdatePivotFilterByIdMutation,
  useDeletePivotFilterByIdMutation,
  useCreatePivotFilterMutation,
  useRunPivotFilterMutation,
} from "../store";
import { pivotFilterApi } from "../store/apis/pivotFilterApi";
import { useCheckAuth } from "../hooks/checkAuth";
import Loader from "../components/Loader";
import InputSJ from "../components/InputSJ";
import RadioButtonSJ from "../components/RadioButtonSJ";
import SlushBucket from "../components/SlushBucket";
import SelectableList from "../components/SelectableList";
import ReferenceModal from "../components/ReferenceModal";
import List from "../components/List";
import LoadingButton from "../components/LoadingButton";
import Prompt from "../components/Prompt";
import { addMessage } from "../store/slices/messageSlice";

import "./pivotReportStyles.css";

import { cloneDeep, isEqual } from "lodash";
import moment from "moment";

import {
  clientConfig,
  departmentConfig,
  projectConfig,
  taskConfig,
  titleConfig,
  userConfig,
  pivotFilterConfig,
} from "../utils/ReferenceConfigs";

import "../components/contentHeader.css";
import * as Yup from "yup";
import ExportPivotToExcel from "../components/ExportPivotToExcel";

const PivotReport = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);
  const [originalFormData, setOriginalFormData] = useState({});
  const [originalAggregates, setOriginalAggregates] = useState([]);
  const [originalColumns, setOriginalColumns] = useState([]);

  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  const defaultFilterState = {
    name: "",
    startDate: "",
    endDate: "",
    period: "Week",
  };
  const [filterState, setfilterState] = useState(defaultFilterState); //data is the initial state
  const [filters, setFilters] = useState([
    clientConfig,
    projectConfig,
    taskConfig,
    departmentConfig,
    titleConfig,
    userConfig,
  ]);

  const storeApi = {
    fetch: useFetchPivotFilterByIdQuery,
    update: useUpdatePivotFilterByIdMutation,
    delete: useDeletePivotFilterByIdMutation,
    create: useCreatePivotFilterMutation,
    run: useRunPivotFilterMutation,
  };

  ///Get APIs from the storey
  const [
    create,
    { data: createData, error: createError, isLoading: createIsLoading },
  ] = storeApi.create();

  const [run, { data: runData, error: runError, isLoading: runIsLoading }] =
    storeApi.run();

  const [
    updateById,
    { data: updateData, error: updateError, isLoading: updateIsloading },
  ] = storeApi.update();

  const [deleteById, { error: deleteError, isLoading: deleteIsLoading }] =
    storeApi.delete();

  const {
    data: filterData,
    error,
    isFetching,
  } = storeApi.fetch({
    id,
  });

  const allErrors =
    error || createError || updateError || deleteError || runError;

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(allErrors, pivotFilterApi);

  //Set scroll on the content section so that when you scroll to the right it is fixed
  const filterSectionRef = useRef(null);
  const divRowHider = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (filterSectionRef.current) {
        window.requestAnimationFrame(() => {
          filterSectionRef.current.style.transform = `translateX(${window.scrollX}px)`;
        });
      }

      if (divRowHider.current) {
        window.requestAnimationFrame(() => {
          divRowHider.current.style.transform = `translateX(${window.scrollX}px)`;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty array means this effect runs once on mount and clean up on unmount

  useEffect(() => {
    //This is the data that is returned from the create api
    //Assumtion is that if this is populated then a new record was just created and you should navigate to iy.
    if (createData && createData.id) {
      if (id === createData.id) {
        return;
      }

      navigate(`/pivotReport/${createData.id}`);

      //navigate("/userlist");
    }
    // eslint-disable-next-line
  }, [navigate, createData]);

  //Put data into state
  useEffect(() => {
    //Once you have data from the api spread it into the state
    if (filterData) {
      console.log(filterData);
      let newFilterState = { ...defaultFilterState };

      //Loop through data.securityLevel.fieldSecurityLevel[table][action] to get the fields that the user has access to
      //This data is coming from the Backend in the authentication.js controller under the getLoginObject function.
      //It is then stored on the user record and retrived with each request
      //Then loop through the config and remove the fields that the user does not have access to
      //The data is also removed on the server side and isn't sent to the client

      let myData = { ...defaultFilterState, ...filterData.records };
      myData = cloneDeep(myData);

      newFilterState = {
        name: myData.name,
        startDate: myData.startDate.toString().substring(0, 10) || "",
        endDate: myData.endDate.toString().substring(0, 10) || "",
        period: myData.period || "Week",
      };

      setSelectedAggregates(
        cloneDeep(myData.aggregates).map((option) => {
          return { name: option, isTotal: false };
        }) || []
      );

      const myFilterItems = [
        cloneDeep(clientConfig),
        cloneDeep(projectConfig),
        cloneDeep(taskConfig),
        cloneDeep(departmentConfig),
        cloneDeep(titleConfig),
        cloneDeep(userConfig),
      ];

      const selectedFilterItemsFromData = [];
      let mySelectedColumns = cloneDeep(myData.columns) || [];
      let myAvailableColumns = [...availableColumns]; //This should be the default columns

      myFilterItems.forEach((item) => {
        //This is the taskFilter from the data filter, so its an array of tasks
        const filterName =
          item.refModalListConfig.recordNames.formLink + "Filter";
        const columnName =
          item.refModalListConfig.recordNames.formLink.toLowerCase();

        if (
          //This is only checking if the user has access to the pivotfilter field and not the actual table
          !(
            filterData.securityLevel.fieldSecurityLevel["pivotFilter"][
              "update"
            ].includes(filterName) &&
            filterData.securityLevel.fieldSecurityLevel["pivotFilter"][
              "read"
            ].includes(filterName)
          ) ||
          !(
            //Does the user have read access to the table?
            (
              filterData.securityLevel.tableSecurityLevel[columnName]["read"] &&
              //The timesheet user has read access to the user table but cannot read any fields.
              //This checks to make sure that the user has access to at least one field in the table
              filterData.securityLevel.fieldSecurityLevel[columnName]["read"]
            ).length
          ) > 0
        ) {
          //User doesnt have access to this filter so remove it from the available columns
          item.isDisabled = true;
          myAvailableColumns = myAvailableColumns.filter(
            (column) => column.toLowerCase() !== columnName
          );
          mySelectedColumns = mySelectedColumns.filter(
            (column) => column.name.toLowerCase() !== columnName
          );

          if (!myData[filterName]) {
            return;
          }
        }

        const filterCategoryFromData = myData[filterName];

        for (var i = 0; i < filterCategoryFromData.length; i++) {
          let myItem = filterCategoryFromData[i];
          myItem.filterCategory = item;
          selectedFilterItemsFromData.push(myItem);
        }
      });

      setSelectedFilterItems(selectedFilterItemsFromData);

      setSelectedColumns(mySelectedColumns);
      setAvailableColumns(myAvailableColumns);

      //Set the filter state for each filter to false if the user does not have access to it
      //This is used to disable the filter buttons
      // clientConfig.isDisabled = !fieldSecUpdate["clientFilter"];

      setFilters(myFilterItems);
      setfilterState(newFilterState);
      setIsDirty(false);
      setOriginalFormData(cloneDeep(newFilterState));
      setOriginalAggregates(cloneDeep(myData.aggregates));
      setOriginalColumns(cloneDeep(myData.columns));
    }

    // eslint-disable-next-line
  }, [filterData]);

  //**************Handle Clicks
  const handleDelete = () => {
    deleteById(id);
    navigate("/pivotReport/-1");
  };

  const handleIsDirty = (newFormData, type) => {
    //IsDirty is also set in the column and aggregate modals
    if (type === "columns") {
      setIsDirty(!isEqual(originalColumns, newFormData));
    } else if (type === "aggregates") {
      setIsDirty(!isEqual(originalAggregates, newFormData));
    } else if (type === "form") {
      setIsDirty(!isEqual(originalFormData, newFormData));
    }
  };

  const [errors, setErrors] = useState({});

  let validationSchema = Yup.object().shape({
    name: Yup.string().required("Filter name is required"),
    startDate: Yup.date()
      .required("Start date is required")
      .max(Yup.ref("endDate"), "Start date must be before end date")
      .nullable()
      .typeError("Start date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date")
      .nullable()
      .typeError("End date is required"),
    aggregates: Yup.array().min(1, "You must select at least one aggregate"),
    columns: Yup.array().min(1, "You must select at least one column"),
    clientFilter: Yup.array().nullable(),
    projectFilter: Yup.array().nullable(),
    taskFilter: Yup.array().nullable(),
    departmentFilter: Yup.array().nullable(),
    titleFilter: Yup.array().nullable(),
    userFilter: Yup.array().nullable(),
  });

  const getValidateBody = () => {
    const clientFilter = filterMapSelectedItems("client");
    const projectFilter = filterMapSelectedItems("project");
    const taskFilter = filterMapSelectedItems("task");
    const departmentFilter = filterMapSelectedItems("department");
    const titleFilter = filterMapSelectedItems("title");
    const userFilter = filterMapSelectedItems("user");

    const body = {
      ...filterState,
      aggregates: selectedAggregates.map((item) => item.name),
      columns: selectedColumns,
      clientFilter,
      projectFilter,
      taskFilter,
      departmentFilter,
      titleFilter,
      userFilter,
    };

    return body;
  };

  const handleSave = async () => {
    const body = getValidateBody();

    try {
      await validationSchema.validate(body, { abortEarly: false });
      setIsDirty(false);
      if (id === "-1") {
        await create(body).unwrap();
      } else {
        await updateById({
          body: body,
          id,
        }).unwrap();
      }
      setErrors({});
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };
  //*********/

  function filterMapSelectedItems(table) {
    const selectedFilterItemsMin = selectedFilterItems
      .filter(
        (item) =>
          item.filterCategory.refModalListConfig.recordNames.formLink === table
      )
      .map((item) => {
        return item._id;
      });
    return selectedFilterItemsMin;
  }

  ////////////Load Modal Section/////////////////////
  const [showLoadModal, setShowLoadModal] = useState(false);
  const handleLoadModalClick = () => {
    setShowLoadModal(true);
  };

  const handleLoadModalClose = () => {
    setShowLoadModal(false);
  };

  const handlePivotFilterClick = (filter) => {
    if (location.pathname === `/pivotReport/${filter._id}`) {
      window.location.reload();
    }
    navigate(`/pivotReport/${filter._id}`);
    setShowLoadModal(false);
  };

  //This just adds the created at field to the list config with the proper timezone as a ref
  const pivotFilterConfigCreatedAt = {
    label: "Updated At", //header label
    render: (pivotFilter) => {
      return new Date(pivotFilter.updatedAt).toLocaleString(undefined, {
        timeZone: timezone,
      });
    }, //How to render the cell
    sortValue: "updatedAt",
    filterValue: "updatedAt",
    filterType: "Date",
  };

  const loadModal = (
    <ReferenceModal onClose={handleLoadModalClose}>
      <List
        handleClick={handlePivotFilterClick}
        config={[
          ...pivotFilterConfig.refModalListConfig.config,
          pivotFilterConfigCreatedAt,
        ]}
        keyFn={pivotFilterConfig.refModalListConfig.keyFn}
        listApi={pivotFilterConfig.refModalListConfig.listApi}
        // useFetchQuery={refModalListConfig.useFetchQuery}
        useFetchQuery={pivotFilterConfig.refModalListConfig.useFetchQuery}
        recordNames={pivotFilterConfig.refModalListConfig.recordNames}
        defaultFilter={""}
        // This was added to fix BUG0001 - Bug undefined on reference Dropdown.
        // I was resetting the listApi in the useCheckAuth hook but that was causing the data to be removed and return undefined
        isRefModal={true}
      ></List>
    </ReferenceModal>
  );
  ////////////End Load Modal Section/////////////////////
  const runReport = async () => {
    const body = getValidateBody();

    //Dont require the name on run report
    delete validationSchema.fields.name;
    try {
      await validationSchema.validate(body, { abortEarly: false });

      await run(body).unwrap();

      setErrors({});
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  const rightHeader = (
    <div className='flex grow mb-1 md:mb-3 justify-center lg:justify-end '>
      <LoadingButton
        onClick={runReport}
        className='text-xs my-2 md:my-0 md:text-base md:w-[118px]'
        isLoading={runIsLoading}
      >
        Run Report
      </LoadingButton>
      {id !== "-1" && (
        <Button
          variant='danger'
          onClick={handleDelete}
          className='ml-3 px-2 hover:px-2 text-sm my-2 md:my-0 md:text-base '
        >
          Delete
        </Button>
      )}

      <Button
        onClick={() => {
          if (id === "-1") {
            window.location.reload();
          } else navigate("/pivotReport/-1");
        }}
        className='ml-3 text-sm my-2 md:my-0 md:text-base '
      >
        New
      </Button>

      <Button
        onClick={handleLoadModalClick}
        className='ml-3 text-sm my-2 md:my-0 md:text-base'
      >
        Load
      </Button>
      <LoadingButton
        onClick={handleSave}
        className='mx-3 text-sm my-2 md:my-0 md:text-base '
        isLoading={updateIsloading || createIsLoading}
      >
        Save
      </LoadingButton>
    </div>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    let period = filterState.period;
    let startDate =
      filterState.startDate === "Invalid date" ? "" : filterState.startDate;
    let endDate =
      filterState.endDate === "Invalid date" ? "" : filterState.endDate;

    if (name === "period" || name === "startDate" || name === "endDate") {
      if (name === "period") {
        period = value;
      } else if (name === "startDate") {
        startDate = value;
      } else if (name === "endDate") {
        endDate = value;
      }

      if (period === "Week") {
        startDate = moment
          .utc(startDate, "YYYY-MM-DD")
          .startOf("isoWeek")
          .weekday(1)
          .format("YYYY-MM-DD");
        endDate = moment
          .utc(endDate, "YYYY-MM-DD")
          .startOf("isoWeek")
          .weekday(0)
          .add(7, "days")
          .format("YYYY-MM-DD");
      } else {
        startDate = moment.utc(startDate).startOf("month").format("YYYY-MM-DD");
        endDate = moment.utc(endDate).endOf("month").format("YYYY-MM-DD");
      }
    }

    startDate = startDate === "Invalid date" ? "" : startDate;
    endDate = endDate === "Invalid date" ? "" : endDate;

    setfilterState((prevState) => ({
      ...prevState,
      [name]: value,
      startDate: startDate,
      endDate: endDate,
    }));
    handleIsDirty(
      {
        ...filterState,
        [name]: value,
        startDate: startDate,
        endDate: endDate,
      },
      "form"
    );
  };
  const filterNameSection = (
    <div className='shadow-sj-btm relative z-[35] sj-bg-C3 pb-4'>
      {(isFetching ||
        updateIsloading ||
        createIsLoading ||
        deleteIsLoading) && (
        <div className='flex justify-center py-2'>
          <Loader />
        </div>
      )}

      <div className='flex justify-center grow'>
        <InputSJ
          item={{
            label: "Filter Name",
            field: "name",
            inputType: "text",
            isDisabled: false,
          }}
          errors={errors}
          handleChange={handleChange}
          state={filterState}
          colspan='6'
          className='px-4 mb-2 mt-3 w-full lg:w-6/12 xl:w-4/12 2xl:w-3/12'
        ></InputSJ>
      </div>
    </div>
  );

  const dateSection = (
    <div className='shadow-sj-btm relative z-30 sj-bg-C3 pb-4'>
      <div className='flex justify-start'>
        <div className='rounded-br-sj sj-bg-grey-100 px-5 shadow-sj-btm pt-2 w-48 text-center font-bold'>
          <p className='m-0 pb-2 leading-6 text-xl'>Dates</p>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row grow justify-between mx-4 xl:mx-40'>
        {/* <Col className='hidden 2xl:flex' xxl='3'></Col> */}
        <RadioButtonSJ
          item={{
            label: "Period",
            field: "period",
            isDisabled: false,
          }}
          className='my-2 w-full lg:w-[25%]'
          errors={{}}
          handleChange={handleChange}
          state={filterState}
          options={["Week", "Month"]}
        ></RadioButtonSJ>

        <InputSJ
          item={{
            label: "Start Date",
            field: "startDate",
            inputType: "date",
            isDisabled: false,
            toolTip:
              "This date will auto adjust to the start of the selected period. i.e., the start of the week or month.",
          }}
          className='my-2 w-full lg:w-[25%]'
          errors={errors}
          handleChange={handleChange}
          state={filterState}
        ></InputSJ>

        <InputSJ
          item={{
            label: "End Date",
            field: "endDate",
            inputType: "date",
            isDisabled: false,
            toolTip:
              "This date will auto adjust to the end of the selected period. i.e., the end of the week or month.",
          }}
          className='my-2 w-full lg:w-[25%]'
          errors={errors}
          handleChange={handleChange}
          state={filterState}
        ></InputSJ>
      </div>
    </div>
  );

  ////////////Modal Section/////////////////////
  //Column Modal
  const [showColumnModal, setShowColumnModal] = useState(false);
  //This will need to pull from the loaded filter
  const [availableColumns, setAvailableColumns] = useState([
    "Client",
    "Project",
    "Task",
    "Department",
    "Title",
    "User",
  ]); //[ "Actual Hours", "Actual Dollars"
  const [selectedColumns, setSelectedColumns] = useState([]); //["Client", "Project", "Task", "Department", "Title", "User"]
  const handleColumnModalClick = () => {
    setShowColumnModal(true);
  };

  const handleColumnClose = () => {
    setShowColumnModal(false);
  };

  const handleColumnChange = (selectedColumns) => {
    handleIsDirty(selectedColumns, "columns");
    setSelectedColumns(selectedColumns);
  };

  const handleColumnConfirm = (selectedColumns) => {
    setSelectedColumns(selectedColumns);
    setShowColumnModal(false);
  };

  const columnModal = (
    <SlushBucket
      onClose={handleColumnClose}
      title='Edit Columns'
      options={availableColumns} //For this you just pass in all the options and then anything that is selected is removed by the component
      handleConfirm={handleColumnConfirm}
      selectedColumns={selectedColumns}
      handleChange={handleColumnChange}
      canTotal={true}
    />
  );

  //Aggergate Modal
  const [showAggregateModal, setShowAggregateModal] = useState(false);
  //This will need to pull from the loaded filter
  const [selectedAggregates, setSelectedAggregates] = useState([]); //[ "Actual Hours", "Actual Dollars"]
  const handleAggregateModalClick = () => {
    setShowAggregateModal(true);
  };

  const handleAggregateClose = () => {
    setShowAggregateModal(false);
  };

  const handleAggregateChange = (columnsFromModal) => {
    handleIsDirty(columnsFromModal, "aggregates");
    setSelectedAggregates(columnsFromModal);
  };

  const handleAggregateConfirm = (columnsFromModal) => {
    setSelectedAggregates(columnsFromModal);
    setShowAggregateModal(false);
  };

  const aggregateModal = (
    <SlushBucket
      onClose={handleAggregateClose}
      title='Edit Aggregates'
      options={["Actual Hours", "Actual Dollars"]}
      handleConfirm={handleAggregateConfirm}
      selectedColumns={selectedAggregates}
      handleChange={handleAggregateChange}
      canTotal={false}
    />
  );
  ////////////End Modal Section/////////////////////
  const pivotButtonClasses = "mt-4 w-full hover:w-full";

  const layoutSection = (
    <div className='shadow-sj-btm relative z-20 sj-bg-C3 pb-4'>
      <div className='flex justify-start'>
        <div className='rounded-br-sj sj-bg-grey-100 px-5 shadow-sj-btm pt-2 w-48 text-center font-bold'>
          <p className='m-0 pb-2 leading-6 text-xl'>Layout</p>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row justify-center w-full grow mb-4 px-4'>
        <div className='w-full lg:w-[25%]  2xl:w-[400px] mx-0 lg:mx-2 2xl:mx-6'>
          <Button
            variant='secondary'
            className={pivotButtonClasses}
            onClick={handleColumnModalClick}
          >
            Edit Columns
          </Button>
          {errors["columns"] && (
            <div className='form-field-error mx-4'>{errors["columns"]}</div>
          )}
        </div>
        <div className='w-full lg:w-[25%] 2xl:w-[400px] mx-0 lg:mx-2 2xl:mx-6'>
          <Button
            variant='secondary'
            className={pivotButtonClasses}
            onClick={handleAggregateModalClick}
          >
            Edit Aggregates
          </Button>
          {errors["aggregates"] && (
            <div className='form-field-error mx-4'>{errors["aggregates"]}</div>
          )}
        </div>
      </div>
    </div>
  );

  ////////////Filter Modal Section/////////////////////

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const handlefilterClick = (filterCategory) => {
    // console.log(filterCategory);
    // const myFilter = cloneDeep(filterCategory);
    // myFilter.refModalListConfig.defaultFilter =
    //   "client._id=658f1bdb60af18e61fe2b674"; //`rateCard._id=${id}^`;

    // console.log(myFilter);
    setShowFilterModal(true);
    setSelectedFilter(filterCategory);
    // setSelectedFilter(myFilter);
  };
  const handleFilterClose = () => {
    setShowFilterModal(false);
  };

  const handleModalConfirm = () => {
    setShowFilterModal(false);
  };

  const handleRowSelect = useCallback(
    (record) => {
      for (let i = 0; i < selectedFilterItems.length; i++) {
        if (selectedFilterItems[i]._id === record._id) {
          setSelectedFilterItems(
            selectedFilterItems.filter(
              (item) => item !== selectedFilterItems[i]
            )
          );

          return;
        }
      }

      record.filterCategory = { ...selectedFilter };

      setSelectedFilterItems([...selectedFilterItems, record]);
    },
    [selectedFilterItems, selectedFilter]
  );

  const filterModal = (
    <SelectableList
      onClose={handleFilterClose}
      title={`Select ${selectedFilter.name}`}
      handleConfirm={handleModalConfirm}
      selectedFilterItems={selectedFilterItems}
      refModalListConfig={selectedFilter.refModalListConfig}
      handleRowSelect={handleRowSelect}
    />
  );

  //this is wrong...
  // const filters = [
  //   clientConfig,
  //   projectConfig,
  //   taskConfig,
  //   departmentConfig,
  //   titleConfig,
  //   userConfig,
  // ];

  const makeFilterButton = (filter) => {
    return (
      <div className='w-full lg:w-[25%] 2xl:w-[400px] mx-0 lg:mx-2 2xl:mx-6'>
        <OverlayTrigger
          placement='top'
          overlay={
            (filter.isDisabled && (
              <Tooltip>Request access from your admin!</Tooltip>
            )) || <></>
          }
        >
          <Button
            disabled={filter.isDisabled}
            variant='secondary'
            className='flex grow justify-between my-2 w-full hover:w-full'
            onClick={() => handlefilterClick(filter)}
          >
            <img
              src={`/images/${filter.img}`}
              alt={`${filter.name} icon`}
              style={{ height: "23px" }}
            />
            <span> {filter.name}</span>
            <span></span>
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  const filterSection = (
    <div className='shadow-sj-btm relative z-10 sj-bg-C3 pb-4'>
      <div className='flex justify-start'>
        <div className='rounded-br-sj sj-bg-grey-100 px-5 shadow-sj-btm pt-2 w-48 text-center font-bold'>
          <p className='m-0 pb-2 leading-6 text-xl'>Filter</p>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row justify-center w-full grow mt-2 px-4'>
        {filters.map((filter, i) => {
          if (i > 2) return null;
          return (
            <Fragment key={`${filter.name}`}>
              {makeFilterButton(filter)}
            </Fragment>
          );
        })}
      </div>
      <div className='flex flex-col lg:flex-row justify-center w-full grow mb-2 px-4'>
        {filters.map((filter, i) => {
          if (i <= 2) return null;
          return (
            <Fragment key={`${filter.name}`}>
              {makeFilterButton(filter)}
            </Fragment>
          );
        })}
      </div>
    </div>
  );

  ////////////End Filter Modal Section/////////////////////

  const selectedFilterItemsSection = (
    <div className='shadow-sj-btm relative z-10 sj-bg-C3 pb-3'>
      <Row className=''>
        <Col md='12'>
          <div className='flex justify-center'>
            <div className='rounded-bottom-2xl sj-bg-grey-100 px-5 shadow-sj-btm'>
              <p className='m-0 pb-2 leading-6 text-xl'>Filtering On</p>
            </div>
          </div>
          <div className='flex justify-center lg:hidden'>
            <p>Tap an item and hit Run Report to remove it from the filter</p>
          </div>
        </Col>
      </Row>
      <Row className=' py-3 sj-bg-C3 justify-center'>
        {selectedFilterItems.map((item) => {
          return (
            // <Col md='2'  className='my-1 mx-1'>
            <Fragment key={item.name}>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Remove filter</Tooltip>}
              >
                <Button
                  variant='info'
                  className='filterSelections mx-4 my-1 font-normal'
                  onClick={() => handleRowSelect(item)}
                >
                  <img
                    src={`/images/${item.filterCategory.img}`}
                    alt={`${item.name} icon`}
                    style={{ height: "23px" }}
                    className='inline'
                  />
                  <span> {item.name}</span>
                  <span></span>
                </Button>
              </OverlayTrigger>
            </Fragment>
            // </Col>
          );
        })}
      </Row>
      {/* {!isFilterCollapsed && (
        <img
          src='/images/Collapse Arrow.svg'
          alt='Collapse Section'
          className='cursor-pointer'
          style={{ position: "absolute", right: "5%" }}
          onClick={() => {
            setIsFilterCollapsed(true);
          }}
        />
      )} */}
    </div>
  );

  const [reportContentState, setReportContentState] = useState([]);

  //This might need to be a piece of state?
  useEffect(() => {
    let reportContent = <></>;
    if (runData) {
      let periodArray = [];
      let startDate = moment.utc(runData.dates.startDate);
      let endDate = moment.utc(runData.dates.endDate);
      if (runData.dates.period.toLowerCase() === "week") {
        while (startDate.isBefore(endDate)) {
          periodArray.push(startDate.format("YYYY-MM-DD"));
          startDate.add(1, "week");
        }
      } else {
        while (startDate.isBefore(endDate)) {
          periodArray.push(startDate.format("MMM-YYYY"));
          startDate.add(1, "month");
        }
      }

      let reportData = cloneDeep(runData.body);

      //sort the data by column ending with the first
      for (let i = runData.columns.length - 1; i >= 0; i--) {
        const column = runData.columns[i].name.toLowerCase();

        reportData.sort((a, b) => {
          let aField = a[column];
          let bField = b[column];

          if (aField < bField) {
            return -1;
          }
          if (aField > bField) {
            return 1;
          }
          return 0;
        });
      }

      //Flatten
      const columnKey = {};
      //WHen you add staffing you'll need a 2nd total
      const blankPeriods = periodArray.map((period) => {
        return { period: period, actualHours: 0, actualDollars: 0 };
      });

      const flattenData = [];

      for (let i = 0; i < reportData.length; i++) {
        //convert period to a string same format as periodArray
        if (runData.dates.period.toLowerCase() === "week")
          reportData[i].period = moment
            .utc(reportData[i].period)
            .format("YYYY-MM-DD");
        else
          reportData[i].period = moment
            .utc(reportData[i].period)
            .format("MMM-YYYY");

        const myItem = reportData[i];
        const myItemKey = myItem.key; //This is a hash from mongo

        if (!columnKey.hasOwnProperty([myItemKey])) {
          const currentRow = flattenData.length;
          columnKey[myItemKey] = currentRow;
          flattenData.push({
            key: myItemKey,
            periods: cloneDeep(blankPeriods),
            ...myItem,
            isTotal: false,
            actualHoursTotal: 0,
            actualDollarsTotal: 0,
            totalDepth: 0,
          });
          delete flattenData[currentRow].period;
          delete flattenData[currentRow].actualHours;
          delete flattenData[currentRow].actualDollars;
        }

        //get the index of the period
        const periodIndex = periodArray.indexOf(myItem.period);
        if (periodIndex === -1) {
          // console.log("Error: Period not found");
          continue;
        }

        if (runData.aggregates.includes("Actual Hours"))
          flattenData[columnKey[myItemKey]].periods[periodIndex].actualHours +=
            myItem.actualHours;

        if (runData.aggregates.includes("Actual Dollars"))
          flattenData[columnKey[myItemKey]].periods[
            periodIndex
          ].actualDollars += myItem.actualDollars;
      }

      //Total on breaks
      //Get the columns that are totals
      const totalColumns = runData.columns.filter((column) => {
        return column.isTotal;
      });

      for (let i = 0; i < totalColumns.length; i++) {
        const myColumn = totalColumns[i];
        const myColumnName = myColumn.name.toLowerCase();
        //Set a priorValue = ''
        let priorValue = "";
        let currentTotal = false;

        //Loop through the report data
        for (let j = 0; j < flattenData.length; j++) {
          const myItem = flattenData[j]; //A row of data as an object
          //Set the value as the row[column]
          const value = myItem[myColumnName]; //Techonology

          if (myItem.isTotal) {
            priorValue = "";
            continue;
          }

          const totalDepth = totalColumns.indexOf(myColumn) + 1;
          flattenData[j].totalDepth = totalDepth;
          //If the value is different than the priorValue then set the priorValue to the value and set a new total
          //Splice in a new row with the total and a prop that says its a total
          if (value !== priorValue) {
            flattenData.splice(j, 0, {
              key: myItem.key + "total" + totalDepth,
              isTotal: true,
              [myColumnName]: value,
              totalDepth: totalDepth,
              totalOn: myColumnName,
              periods: cloneDeep(blankPeriods),
              className: `prTotal${totalDepth}`,
              actualDollarsTotal: 0,
              actualHoursTotal: 0,
            });
            //Set a current total variable that is a reference to the total row
            currentTotal = flattenData[j];
            j++;
          }

          //loop through all the periods and assign them to the total row
          for (let k = 0; k < myItem.periods.length; k++) {
            currentTotal.periods[k].actualHours +=
              myItem.periods[k].actualHours;
            currentTotal.periods[k].actualDollars +=
              myItem.periods[k].actualDollars;
          }

          priorValue = value;
        }
      }

      //Add grand totals to the data
      const grandTotalRow = {
        key: "grandTotal",
        isTotal: true,
        [runData.columns[0].name.toLowerCase()]: "Grand Total",
        totalDepth: 1,
        totalOn: "Grand Total",
        periods: cloneDeep(blankPeriods),
        className: "grandTotal",
        actualDollarsTotal: 0,
        actualHoursTotal: 0,
      };

      for (let i = 0; i < flattenData.length; i++) {
        const myItem = flattenData[i];
        for (let j = 0; j < myItem.periods.length; j++) {
          if (!myItem.isTotal) {
            grandTotalRow.periods[j].actualHours +=
              myItem.periods[j].actualHours;
            grandTotalRow.periods[j].actualDollars +=
              myItem.periods[j].actualDollars;

            //Add row totals
            grandTotalRow.actualHoursTotal += myItem.periods[j].actualHours;
            grandTotalRow.actualDollarsTotal += myItem.periods[j].actualDollars;
          }

          myItem.actualHoursTotal += myItem.periods[j].actualHours;
          myItem.actualDollarsTotal += myItem.periods[j].actualDollars;
        }
      }

      flattenData.splice(0, 0, grandTotalRow);
      reportContent = (
        <>
          <div
            ref={divRowHider}
            className='sticky w-full text-center my-2 divPlaceHolder'
          >
            <div className='flex justify-between w-full'>
              <ExportPivotToExcel
                data={flattenData}
                columns={runData.columns}
                aggregates={runData.aggregates}
                periods={periodArray}
                fileName={runData.name || "Pivot Report"}
              />
              <span className='leading-8'>{runData.name} </span>
              <span></span>
            </div>
          </div>
          <table className='prTable' id='pivotTable'>
            <thead>
              <tr>
                {runData.columns.map((column) => {
                  return (
                    <th key={column.name} rowSpan={2} className='topRowTH'>
                      {column.name}
                    </th>
                  );
                })}
                {periodArray.map((period) => {
                  return (
                    <th
                      key={period}
                      colSpan={runData.aggregates.length}
                      className='topRowTH'
                    >
                      {period}
                    </th>
                  );
                })}
                <th
                  key={"totals"}
                  colSpan={runData.aggregates.length}
                  className='topRowTH'
                >
                  Totals
                </th>
              </tr>
              <tr>
                {periodArray.map((period) => {
                  return runData.aggregates.map((aggregate) => {
                    return (
                      <th
                        className='aggregateTh bottomRowTH'
                        key={`${aggregate}_${period}`}
                      >
                        {aggregate}
                      </th>
                    );
                  });
                })}
                {runData.aggregates.map((aggregate) => {
                  return (
                    <th
                      className='aggregateTh bottomRowTH'
                      key={`${aggregate}totals`}
                    >
                      {aggregate}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {flattenData.map((row) => {
                return (
                  <tr key={row.key}>
                    {runData.columns.map((column, i) => {
                      if (row.isTotal && row.totalDepth <= i) return null;
                      return (
                        <td
                          key={`${row.key}${column.name}`}
                          className={
                            row.isTotal && row.totalDepth <= i + 1
                              ? `${row.className} text-left`
                              : (row.totalDepth >= i + 1 && "prDetail") || ""
                          }
                          colSpan={
                            (row.isTotal &&
                              row.hasOwnProperty(column.name.toLowerCase()) &&
                              runData.columns.length - row.totalDepth + 1) ||
                            1
                          }
                        >
                          {row.hasOwnProperty(column.name.toLowerCase()) &&
                          (row.totalDepth <= i || row.isTotal)
                            ? row[column.name.toLowerCase()]
                            : ""}
                        </td>
                      );
                    })}
                    {row.periods.map((period) => {
                      return runData.aggregates.map((aggregate) => {
                        let field = "actualHours";
                        if (aggregate === "Actual Dollars")
                          field = "actualDollars";

                        let value = period[field];

                        if (value === 0) value = "";
                        else if (field === "actualDollars")
                          value = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(value);
                        else
                          value = value.toLocaleString("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          });

                        return (
                          <td
                            key={`${period.period}${row.key}${aggregate}`}
                            className={row.isTotal ? `${row.className}` : ""}
                          >
                            {value}
                          </td>
                        );
                      });
                    })}
                    {/* Add the totals - This could be refactored later*/}
                    {runData.aggregates.map((aggregate) => {
                      let field = "actualHoursTotal";
                      if (aggregate === "Actual Dollars")
                        field = "actualDollarsTotal";

                      let value = row[field];

                      if (value === 0) value = "";
                      else if (field === "actualDollarsTotal")
                        value = new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(value);
                      else
                        value = value.toLocaleString("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        });

                      return (
                        <td
                          key={`${row.key}${aggregate}total`}
                          className={
                            row.isTotal ? `${row.className}` : "bg-gray-200"
                          }
                        >
                          {value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );

      setReportContentState(reportContent);
    }
  }, [runData]);

  let content;

  let displayError =
    updateError || deleteError || createError || runError || error;
  let displaySuccess = updateData || createData;

  if (runIsLoading) {
    content = <Loader />;
  }

  // else content = reportContentState;

  useEffect(() => {
    if (displayError)
      dispatch(
        addMessage({
          message: displayError?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
  }, [displayError, dispatch]);

  useEffect(() => {
    if (displaySuccess) {
      dispatch(
        addMessage({
          message: displaySuccess.message,
          variant: "success",
        })
      );
    }
  }, [displaySuccess, dispatch]);

  return (
    <>
      <Prompt isDirty={isDirty} />
      <div className='sj-bg-C3 min-h-screen min-w-full bg-white'>
        <div ref={filterSectionRef}>
          <ContentHeader
            title='Pivot Report'
            subtitle={filterState.name}
            // subtitle={data && data.name}
            align='left'
            // leftHeader={leftHeader}
            // rightHeader={rightHeader}
          >
            <div className='flex justify-center lg:justify-between grow items-center'>
              <div className='hidden lg:flex'></div>
              {rightHeader}
            </div>
          </ContentHeader>
          {!isFilterCollapsed && filterNameSection}
          {!isFilterCollapsed && dateSection}
          {showLoadModal && loadModal}
          {showColumnModal && columnModal}
          {showAggregateModal && aggregateModal}
          {showFilterModal && filterModal}
          {!isFilterCollapsed && layoutSection}
          {!isFilterCollapsed && filterSection}
          {!isFilterCollapsed && selectedFilterItemsSection}
          {content}
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                {isFilterCollapsed ? "Expand Section" : "Collapse Section"}
              </Tooltip>
            }
          >
            <img
              src='/images/Collapse Arrow.svg'
              alt='Collapse Section'
              className='cursor-pointer'
              style={{
                position: "absolute",
                right: "5%",
                transform: isFilterCollapsed
                  ? "translateY(-30px) rotate(180deg)"
                  : "translateY(-20px)",
                zIndex: "70",
              }}
              onClick={() => {
                setIsFilterCollapsed(!isFilterCollapsed);
              }}
            />
          </OverlayTrigger>
        </div>
        <div className='mx-4'>{reportContentState && reportContentState}</div>
      </div>
    </>
  );
};

export default requireAuth(PivotReport);
