import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchVacationMilestoneByIdQuery,
  useUpdateVacationMilestoneByIdMutation,
} from "../store";
import { vacationMilestoneApi } from "../store/apis/vacationMilestoneApi";
import VacationMilestoneCustom from "../components/customForm/VacationMilestoneCustom";
import * as Yup from "yup";

import { useSecLevel } from "../hooks/formHooks";

const VacationMilestoneForm = () => {
  const storeApi = {
    fetch: useFetchVacationMilestoneByIdQuery,
    update: useUpdateVacationMilestoneByIdMutation,
    //I dont have a create or delete for this table so i am using a placeholder so formSJ doesnt break
    create: () => {
      const create = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [create, { data, error, isLoading }];
    },
    delete: () => {
      const deleteById = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [deleteById, { data, error, isLoading }];
    },
  };

  const [secLevel] = useSecLevel(["vacationMilestone"]);

  const config = [
    {
      label: "Vacation Milestone",
      field: "milestones",
      defaultValue: "",
      inputType: "custom",
      customComponent: {
        component: VacationMilestoneCustom,
        props: { prop1: "value1" },
      },
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  return (
    <FormSJ
      storeApi={storeApi}
      apiToReset={vacationMilestoneApi}
      config={config}
      validationSchema={validationSchema}
      relatedListConfig={[]}
      secLevel={secLevel}
      formTitle='Vacation Milestone'
      table='vacationMilestone'
      redirect='vacationMilestone'
    />
  );
};

export default requireAuth(VacationMilestoneForm);
