import List from "../components/List";
import requireAuth from "../requireAuth";

import { leaveTypeApi } from "../store/apis/leaveTypeApi";
import { useFetchLeaveTypesQuery, useLeaveTypeExportListQuery } from "../store";

const LeaveTypeList = () => {
  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (leaveType) => `/leaveType/${leaveType._id}`,
    },

    {
      label: "Name",
      render: (leaveType) => leaveType?.name || "",
      sortValue: "name",
      filterValue: "name",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Accrues Vacation", //header label
      render: (leaveType) => {
        return leaveType.accruesVacation.toString() || "";
        // return new Date(leaveType.startDate).toString();
      }, //How to render the cell
      sortValue: "accruesVacation",
      filterValue: "accruesVacation",
      filterType: "Boolean",
    },
    {
      label: "Timesheet Admin Only", //header label
      render: (leaveType) => {
        return leaveType.adminOnly.toString() || "";
        // return new Date(leaveType.startDate).toString();
      }, //How to render the cell
      sortValue: "adminOnly",
      filterValue: "adminOnly",
      filterType: "Boolean",
    },
    {
      label: "Active", //header label
      render: (leaveType) => {
        return leaveType.active.toString() || "";
        // return new Date(leaveType.startDate).toString();
      }, //How to render the cell
      sortValue: "active",
      filterValue: "active",
      filterType: "Boolean",
    },
    {
      label: "System Leave Type", //header label
      render: (leaveType) => {
        return leaveType.systemType.toString() || "";
        // return new Date(leaveType.startDate).toString();
      }, //How to render the cell
      sortValue: "systemType",
      filterValue: "systemType",
      filterType: "Boolean",
    },
  ];

  //What is the key for the data?
  const keyFn = (leaveType) => {
    return leaveType._id;
  };

  const recordNames = {
    title: "Leave Types",
    formLink: "leaveType",
    listLink: "leaveType", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  return (
    <List
      config={config}
      keyFn={keyFn}
      listApi={leaveTypeApi}
      useFetchQuery={useFetchLeaveTypesQuery}
      useExportQuery={useLeaveTypeExportListQuery}
      recordNames={recordNames}
    ></List>
  );
};

export default requireAuth(LeaveTypeList);
