import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchLeaveTypeByIdQuery,
  useUpdateLeaveTypeByIdMutation,
  useDeleteLeaveTypeByIdMutation,
  useCreateLeaveTypeMutation,
} from "../store";
import { leaveTypeApi } from "../store/apis/leaveTypeApi";

import * as Yup from "yup";
import { useSecLevel } from "../hooks/formHooks";

const LeaveTypeForm = () => {
  const storeApi = {
    fetch: useFetchLeaveTypeByIdQuery,
    update: useUpdateLeaveTypeByIdMutation,
    delete: useDeleteLeaveTypeByIdMutation,
    create: useCreateLeaveTypeMutation,
  };

  const [secLevel] = useSecLevel(["leaveType"]);

  const config = [
    {
      label: "Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      col: "7",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .required("Name is required"),
    },
    {
      label: "Accrues Vacation",
      field: "accruesVacation",
      defaultValue: true,
      inputType: "checkbox",
      render: (data) => data.accruesVacation,
      toolTip:
        "Uncheck for special leaves of absence that should not accrue vacation time",
    },
    {
      label: "Timesheet Admin Only",
      field: "adminOnly",
      defaultValue: true,
      inputType: "checkbox",
      render: (data) => data.adminOnly,
      toolTip:
        "Only users with the timesheet admin role can enter this leave type",
    },
    {
      label: "Active",
      field: "active",
      defaultValue: true,
      inputType: "checkbox",
      render: (data) => data.active,
      toolTip: "This will not show up as an option for time entry if unchecked",
    },
    {
      label: "System Leave Type",
      field: "systemType",
      defaultValue: false,
      inputType: "checkbox",
      render: (data) => data.systemType,
      toolTip: "This is a system leave type. It cannot be edited or deleted.",
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  return (
    <FormSJ
      storeApi={storeApi}
      apiToReset={leaveTypeApi}
      config={config}
      validationSchema={validationSchema}
      relatedListConfig={[]}
      secLevel={secLevel}
      formTitle='Leave Type'
      table='leaveType'
      redirect='leaveType'
    />
  );
};

export default requireAuth(LeaveTypeForm);
