import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const leaveTypeApi = createApi({
  reducerPath: "leaveTypes",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/leaveType`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchLeaveTypes: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "LeaveType" }, { type: "LeaveTypeList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      //This is only to fetch the leave types for the time sheet
      fetchLeaveTypesTimeSheet: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "LeaveType" }, { type: "LeaveTypeList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/timeSheet/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchLeaveTypeById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "LeaveType" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateLeaveTypeById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "LeaveType" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
      deleteLeaveTypeById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "LeaveTypeList" }];
        },
        query: (id) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
      }),
      createLeaveType: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "LeaveTypeList" }];
        },
        query: (data) => {
          return {
            url: `/`,
            method: "POST",
            body: data,
          };
        },
      }),
      leaveTypeExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "LeaveType" }, { type: "LeaveTypeList" }];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchLeaveTypesQuery,
  useFetchLeaveTypeByIdQuery,
  useUpdateLeaveTypeByIdMutation,
  useDeleteLeaveTypeByIdMutation,
  useCreateLeaveTypeMutation,
  useLeaveTypeExportListQuery,
  useFetchLeaveTypesTimeSheetQuery,
} = leaveTypeApi;
export { leaveTypeApi };
