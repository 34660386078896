import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

//UserVacation Specific imports
import { userVacationApi } from "../store/apis/userVacationApi";
import { useFetchUserVacationsQuery } from "../store";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import {
  useFetchListViewByTableQuery,
  useUserVacationExportListQuery,
} from "../store";
import { useFinalListConfig } from "../hooks/listHooks";

const UserVacationList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } =
    useFetchListViewByTableQuery("userVacation");

  //Checks to see if the userVacation is logged in and has access to this page
  //This is only needed if you are using the list view
  useCheckAuth(error, listViewApi);

  //****You have to also setup the list view in the Backend*****
  ////////Config Columns/Headers
  // Columns
  const config = [
    {
      label: "i",
      render: (userVacation) => `/userVacation/${userVacation._id}`,
    },
    {
      label: "User",
      render: (userVacation) => userVacation?.user?.name || "",
      sortValue: "user",
      filterValue: "user",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "YTD Hours Earned",
      render: (userVacation) => userVacation.ytdHoursEarned || 0,
      sortValue: "ytdHoursEarned",
      filterValue: "ytdHoursEarned",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "YTD Hours Taken",
      render: (userVacation) => userVacation.ytdHoursTaken || 0,
      sortValue: "ytdHoursTaken",
      filterValue: "ytdHoursTaken",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "YTD Hours Remaining",
      render: (userVacation) => userVacation.ytdHoursRemaining || 0,
      sortValue: "ytdHoursRemaining",
      filterValue: "ytdHoursRemaining",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "YE Hours Earned",
      render: (userVacation) => userVacation.yeHoursEarned || 0,
      sortValue: "yeHoursEarned",
      filterValue: "yeHoursEarned",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "YE Hours Taken",
      render: (userVacation) => userVacation.yeHoursTaken || 0,
      sortValue: "yeHoursTaken",
      filterValue: "yeHoursTaken",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "YE Hours Remaining",
      render: (userVacation) => userVacation.yeHoursRemaining || 0,
      sortValue: "yeHoursRemaining",
      filterValue: "yeHoursRemaining",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Count of Adjustments",
      render: (userVacation) => userVacation?.adjustments?.length || 0,

      sortValue: "adjustments",
      filterValue: "adjustments",
      filterType: "Number",
    },
  ];

  //What is the key for the data?

  const keyFn = (userVacation) => {
    return userVacation._id;
  };

  const recordNames = {
    title: "User's Vacation",
    formLink: "userVacation",
    listLink: "userVacation", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={userVacationApi}
        useFetchQuery={useFetchUserVacationsQuery}
        useExportQuery={useUserVacationExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(UserVacationList);
